import React from 'react'
import { SvgIcon } from '@material-ui/core'

export const MajorIcon = props => (
  <SvgIcon {...props}>
    <g>
      <polygon points="2.146,9.344 5.264,19.647 18.646,19.647 21.924,9.344 15.797,13.112 11.82,4.234 8.059,12.946" />
      <circle cx="1.834" cy="7.173" r="1.745" />
      <circle cx="22.22" cy="7.173" r="1.745" />
      <circle cx="11.839" cy="1.87" r="1.745" />
      <rect x="5.102" y="21.77" width="13.491" height="2.23" />
    </g>
  </SvgIcon>
)

export const CupsIcon = props => (
  <SvgIcon {...props}>
    <path d="M10.271,15.82c-0.488-0.445-0.953-0.897-1.355-1.408c-0.372-0.471-0.742-0.941-1.093-1.428
      c-0.44-0.611-0.832-1.255-1.152-1.937c-0.345-0.735-0.689-1.475-0.979-2.234c-0.35-0.917-0.627-1.861-0.813-2.829
      C4.791,5.521,4.695,5.057,4.63,4.589C4.559,4.082,4.503,3.571,4.471,3.06C4.429,2.375,4.405,1.687,4.396,1.001
      C4.392,0.779,4.438,0.55,4.502,0.335c0.07-0.233,0.263-0.328,0.508-0.328c1.609,0.002,3.218,0,4.828,0.001
      c1.465,0.001,2.93,0.006,4.396,0.007c1.328,0,2.657-0.004,3.985-0.007c0.339-0.001,0.68,0.003,1.019-0.009
      c0.353-0.013,0.544,0.19,0.591,0.499c0.036,0.244,0.033,0.494,0.038,0.742c0.004,0.236-0.003,0.473-0.01,0.71
      c-0.014,0.434-0.018,0.868-0.053,1.3c-0.032,0.408-0.096,0.813-0.149,1.22c-0.118,0.896-0.309,1.775-0.537,2.649
      c-0.237,0.913-0.548,1.798-0.914,2.667c-0.368,0.875-0.814,1.708-1.309,2.516c-0.422,0.688-0.89,1.343-1.416,1.953
      c-0.375,0.435-0.772,0.851-1.166,1.271c-0.096,0.102-0.209,0.187-0.32,0.284c0.026,0.063,0.057,0.127,0.078,0.195
      c0.074,0.232-0.002,0.424-0.162,0.596c-0.145,0.153-0.289,0.307-0.412,0.474c-0.069,0.094-0.1,0.216-0.139,0.327
      c-0.022,0.063-0.004,0.11,0.063,0.147c0.518,0.287,0.668,0.877,0.564,1.409c-0.043,0.218-0.141,0.398-0.275,0.57
      c-0.025,0.032-0.034,0.092-0.025,0.135c0.133,0.661,0.51,1.181,0.964,1.659c0.298,0.312,0.671,0.518,1.009,0.771
      c0.443,0.334,0.759,0.756,0.863,1.319c0.058,0.313-0.165,0.61-0.498,0.609c-2.598-0.008-5.197-0.006-7.796-0.007
      c-0.306,0-0.518-0.281-0.466-0.582c0.125-0.726,0.563-1.207,1.178-1.559c0.39-0.223,0.705-0.532,0.984-0.877
      c0.311-0.387,0.536-0.817,0.671-1.298c0.022-0.079,0.019-0.133-0.037-0.196c-0.444-0.501-0.41-1.533,0.279-1.949
      c0.071-0.043,0.094-0.084,0.068-0.175c-0.059-0.212-0.164-0.392-0.314-0.549c-0.095-0.1-0.192-0.196-0.284-0.299
      c-0.177-0.198-0.162-0.419-0.062-0.646c0.009-0.019,0.02-0.038,0.029-0.057C10.272,15.827,10.271,15.823,10.271,15.82z"
    />
  </SvgIcon>
)

export const SwordsIcon = props => (
  <SvgIcon {...props}>
    <path d="M8.879,17.427C8.545,17.09,8.214,16.76,7.866,16.41c4.002-4,8.007-8.004,12.029-12.026c-0.105-0.087-0.213-0.176-0.317-0.26
      c-3.99,3.988-7.997,7.995-11.984,11.98c-0.367-0.373-0.693-0.704-1.034-1.049c0.126-0.146,0.294-0.347,0.471-0.542
      c0.723-0.804,1.45-1.604,2.172-2.409c0.92-1.026,1.836-2.055,2.753-3.082c0.771-0.864,1.545-1.728,2.315-2.596
      c0.971-1.092,1.969-2.166,2.899-3.292c0.484-0.585,1.109-0.844,1.773-1.11c1.598-0.64,3.191-1.301,4.787-1.954
      c0.055-0.021,0.115-0.034,0.221-0.065c-0.095,0.253-0.174,0.472-0.262,0.688c-0.716,1.777-1.425,3.557-2.159,5.327
      c-0.087,0.207-0.291,0.375-0.468,0.533c-0.703,0.639-1.414,1.268-2.121,1.898c-0.805,0.714-1.612,1.422-2.413,2.137
      c-0.655,0.586-1.304,1.18-1.96,1.766c-0.796,0.709-1.596,1.412-2.394,2.121c-0.505,0.45-1.003,0.909-1.508,1.36
      C10.075,16.366,9.481,16.893,8.879,17.427z"
    />
    <path d="M5.526,16.737c-0.735-0.687-1.469-1.37-2.199-2.048c0.403-0.399,0.732-0.729,1.051-1.044
      c1.966,1.968,3.948,3.95,5.927,5.929c-0.313,0.306-0.649,0.634-0.995,0.972c-0.627-0.628-1.287-1.281-1.933-1.947
      c-0.15-0.154-0.228-0.137-0.368,0.008c-0.832,0.844-1.677,1.678-2.507,2.522c-0.08,0.082-0.147,0.232-0.133,0.34
      c0.158,1.156-0.637,2.153-1.614,2.413c-1.206,0.32-2.473-0.481-2.709-1.709c-0.225-1.174,0.513-2.321,1.738-2.567
      c0.254-0.049,0.527-0.004,0.792-0.008c0.053-0.001,0.124-0.011,0.158-0.045c0.917-0.915,1.83-1.834,2.744-2.75
      C5.48,16.798,5.48,16.787,5.48,16.78C5.496,16.767,5.511,16.751,5.526,16.737z"
    />
  </SvgIcon>
)

export const PentaclesIcon = props => (
  <SvgIcon {...props}>
    <path d="M23.878,8.882c-2.122,1.579-4.247,3.157-6.421,4.775c-0.184-0.505-0.355-0.979-0.548-1.508
      c0.781-0.557,1.557-1.108,2.438-1.735c-2.845,0-5.574,0-8.379,0c0.207-0.58,0.384-1.079,0.591-1.656c4.117,0,8.219,0,12.319,0
      C23.878,8.799,23.878,8.841,23.878,8.882z"
    />
    <path d="M12.114,0.748c0.814,2.402,1.631,4.807,2.457,7.242c-0.517,0-1.039,0-1.595,0
      c-0.313-0.869-0.625-1.738-0.992-2.762c-0.931,2.614-1.81,5.087-2.716,7.637c-0.455-0.35-0.857-0.66-1.258-0.968
      c1.292-3.736,2.572-7.443,3.854-11.15C11.947,0.748,12.03,0.748,12.114,0.748z"
    />
    <path d="M12.492,18.209c0.495-0.345,0.92-0.64,1.366-0.951c1.03,0.691,2.057,1.383,3.231,2.172
      c-1.032-2.858-2.007-5.554-2.994-8.281c0.561,0,1.056,0,1.632,0c1.426,3.953,2.862,7.934,4.367,12.104
      C17.475,21.514,15.005,19.876,12.492,18.209z"
    />
    <path d="M14.872,15.63c-3.642,2.527-7.242,5.024-10.986,7.622c1.028-2.847,2.002-5.542,3.002-8.31
      c0.446,0.349,0.846,0.664,1.276,1.003c-0.409,1.124-0.81,2.223-1.272,3.485c2.559-1.831,4.969-3.557,7.423-5.313
      C14.504,14.63,14.678,15.103,14.872,15.63z"
    />
    <path d="M0.121,8.866c2.775,0,5.422,0,8.111,0c-0.186,0.519-0.36,1.002-0.542,1.508c-0.984,0-1.959,0-3.117,0
      c2.333,1.761,4.537,3.422,6.8,5.129c-0.477,0.313-0.903,0.596-1.358,0.896C6.753,13.915,3.486,11.428,0.121,8.866z"
    />
  </SvgIcon>
)

export const WandsIcon = props => (
  <SvgIcon {...props}>
    <path d="M23.274,0.844c-0.334,0.325-0.577,0.605-0.868,0.822
      c-0.129,0.096-0.358,0.088-0.539,0.078c-0.491-0.03-0.652-0.032-0.82,0.493c-0.212,0.672-0.47,1.33-0.705,1.995
      c-0.008,0.021,0.008,0.05,0.021,0.11c0.373-0.128,0.748-0.235,1.104-0.386c0.387-0.163,0.761-0.359,1.13-0.56
      c0.091-0.05,0.183-0.166,0.204-0.267c0.042-0.19,0.062-0.394,0.038-0.586c-0.026-0.232,0.045-0.391,0.214-0.541
      c0.247-0.218,0.477-0.457,0.749-0.72c0.222,0.777,0.311,1.505,0.073,2.245c-0.008,0.025-0.027,0.049-0.029,0.075
      c-0.05,0.519-0.4,0.689-0.854,0.806c-0.518,0.131-1.017,0.329-1.519,0.513c-0.954,0.351-1.712,0.996-2.442,1.672
      c-0.349,0.323-0.663,0.68-0.996,1.019c-0.283,0.289-0.359,0.668-0.479,1.033c-0.257,0.771-0.74,1.344-1.517,1.634
      c-0.165,0.062-0.352,0.099-0.524,0.093c-0.255-0.009-0.43,0.111-0.589,0.27c-0.322,0.324-0.687,0.622-0.942,0.993
      c-0.228,0.33-0.351,0.738-0.492,1.12c-0.305,0.824-0.986,1.31-1.853,1.328c-0.139,0.004-0.302,0.064-0.407,0.153
      c-0.361,0.31-0.692,0.653-1.052,0.964c-0.295,0.255-0.332,0.601-0.389,0.945c-0.128,0.789-0.895,1.518-1.69,1.615
      c-0.358,0.042-0.685,0.132-0.948,0.421c-0.35,0.386-0.75,0.724-1.092,1.112c-0.116,0.132-0.179,0.357-0.176,0.538
      c0.009,0.669-0.375,1.085-0.877,1.413c-0.136,0.09-0.346,0.115-0.514,0.098c-0.767-0.08-1.235,0.348-1.654,0.899
      c-0.286,0.379-0.589,0.746-0.912,1.095c-0.244,0.26-0.406,0.196-0.481-0.159C1.4,22.97,1.302,22.856,1.067,22.839
      c-0.318-0.023-0.635-0.089-0.949-0.151c-0.055-0.01-0.142-0.096-0.134-0.122c0.019-0.07,0.08-0.172,0.134-0.178
      c0.917-0.108,1.507-0.756,2.133-1.319c0.794-0.715,1.545-1.476,2.305-2.228c0.087-0.085,0.139-0.239,0.151-0.365
      c0.067-0.683,0.373-1.211,0.924-1.619c0.316-0.235,0.664-0.332,1.039-0.396c0.128-0.021,0.264-0.081,0.363-0.163
      c0.47-0.387,0.932-0.787,1.388-1.191c0.063-0.057,0.099-0.163,0.112-0.253c0.042-0.263,0.033-0.538,0.104-0.791
      c0.185-0.661,1.071-1.242,1.768-1.244c0.203,0,0.442-0.109,0.602-0.245c0.438-0.371,0.845-0.779,1.253-1.182
      c0.067-0.066,0.108-0.183,0.116-0.281c0.052-0.589,0.21-1.145,0.681-1.524c0.369-0.298,0.777-0.577,1.314-0.515
      c0.12,0.015,0.281-0.062,0.377-0.15c0.443-0.406,0.877-0.826,1.294-1.257c0.098-0.099,0.172-0.262,0.176-0.399
      c0.021-0.612,0.365-1.058,0.752-1.469c0.14-0.151,0.379-0.249,0.591-0.291c1.021-0.207,1.523-0.95,1.873-1.821
      c0.343-0.853,0.62-1.73,0.941-2.59c0.041-0.11,0.159-0.216,0.268-0.27c0.535-0.26,1.106-0.378,1.693-0.291
      C22.643,0.579,22.935,0.728,23.274,0.844z"
    />
  </SvgIcon>
)
/* eslint-disable */
export const GooglePlayButton = props => (
  <SvgIcon width="217.614px" height="64.053px" viewBox="0 0 217.614 64.053" {...props}>
    <g fill="#000000">
    	<path d="M209.425,63.245H8.189c-4.077,0-7.382-3.305-7.382-7.382V8.19c0-4.077,3.305-7.383,7.382-7.383h201.235
    		c4.078,0,7.383,3.306,7.383,7.383v47.673C216.808,59.94,213.503,63.245,209.425,63.245z"/>
      <path fill="#000000" d="M209.425,64.053H8.189C3.674,64.053,0,60.379,0,55.863V8.19C0,3.674,3.674,0,8.189,0h201.235
    		c4.516,0,8.189,3.674,8.189,8.19v47.673C217.614,60.379,213.94,64.053,209.425,64.053z M8.189,1.615
    		c-3.625,0-6.574,2.949-6.574,6.575v47.673c0,3.625,2.949,6.574,6.574,6.574h201.235c3.625,0,6.574-2.949,6.574-6.574V8.19
    		c0-3.626-2.949-6.575-6.574-6.575H8.189z"/>
    	<path fill="#FFFFFF" d="M109.776,35.029c-3.783,0-6.867,2.876-6.867,6.841c0,3.938,3.083,6.841,6.867,6.841
    		s6.866-2.902,6.866-6.841C116.642,37.905,113.559,35.029,109.776,35.029z M109.776,46.016c-2.073,0-3.861-1.71-3.861-4.146
    		c0-2.462,1.788-4.146,3.861-4.146c2.073,0,3.86,1.685,3.86,4.146C113.636,44.306,111.849,46.016,109.776,46.016z M94.796,35.029
    		c-3.783,0-6.867,2.876-6.867,6.841c0,3.938,3.083,6.841,6.867,6.841s6.867-2.902,6.867-6.841
    		C101.663,37.905,98.58,35.029,94.796,35.029z M94.796,46.016c-2.073,0-3.861-1.71-3.861-4.146c0-2.462,1.788-4.146,3.861-4.146
    		s3.861,1.685,3.861,4.146C98.657,44.306,96.869,46.016,94.796,46.016z M76.978,37.128v2.902h6.944
    		c-0.208,1.633-0.751,2.824-1.581,3.653c-1.011,1.011-2.591,2.125-5.364,2.125c-4.275,0-7.618-3.446-7.618-7.722
    		s3.342-7.722,7.618-7.722c2.306,0,3.991,0.907,5.234,2.073l2.047-2.048c-1.736-1.658-4.042-2.928-7.281-2.928
    		c-5.856,0-10.779,4.768-10.779,10.624c0,5.855,4.923,10.624,10.779,10.624c3.161,0,5.545-1.037,7.411-2.98
    		c1.917-1.917,2.514-4.612,2.514-6.788c0-0.674-0.052-1.296-0.156-1.814H76.978z M149.851,39.383
    		c-0.57-1.529-2.307-4.354-5.855-4.354c-3.525,0-6.453,2.772-6.453,6.841c0,3.835,2.902,6.841,6.789,6.841
    		c3.135,0,4.949-1.918,5.701-3.032l-2.332-1.555c-0.777,1.141-1.84,1.892-3.369,1.892s-2.617-0.699-3.316-2.073l9.146-3.782
    		L149.851,39.383z M140.522,41.663c-0.078-2.644,2.047-3.99,3.576-3.99c1.191,0,2.201,0.596,2.539,1.45L140.522,41.663z
    		 M133.085,48.296h3.005V28.188h-3.005V48.296z M128.163,36.558h-0.104c-0.674-0.803-1.97-1.528-3.603-1.528
    		c-3.42,0-6.556,3.006-6.556,6.866c0,3.835,3.136,6.815,6.556,6.815c1.633,0,2.929-0.726,3.603-1.555h0.104v0.984
    		c0,2.617-1.399,4.017-3.654,4.017c-1.839,0-2.979-1.321-3.445-2.437l-2.617,1.089c0.751,1.813,2.746,4.042,6.063,4.042
    		c3.524,0,6.504-2.072,6.504-7.126V35.443h-2.85V36.558z M124.716,46.016c-2.073,0-3.81-1.736-3.81-4.12
    		c0-2.409,1.736-4.172,3.81-4.172c2.047,0,3.653,1.763,3.653,4.172C128.37,44.279,126.763,46.016,124.716,46.016z M163.931,28.188
    		h-7.191v20.107h3v-7.618h4.191c3.326,0,6.596-2.408,6.596-6.244S167.257,28.188,163.931,28.188z M164.007,37.88h-4.268v-6.893
    		h4.268c2.244,0,3.52,1.858,3.52,3.446C167.526,35.992,166.251,37.88,164.007,37.88z M182.556,34.992
    		c-2.174,0-4.424,0.957-5.355,3.078l2.664,1.112c0.568-1.112,1.629-1.475,2.742-1.475c1.553,0,3.131,0.932,3.156,2.587v0.207
    		c-0.543-0.311-1.707-0.776-3.131-0.776c-2.871,0-5.793,1.578-5.793,4.527c0,2.69,2.354,4.424,4.992,4.424
    		c2.018,0,3.129-0.906,3.828-1.966h0.104v1.552h2.896v-7.709C188.659,36.984,185.995,34.992,182.556,34.992z M182.192,46.012
    		c-0.982,0-2.354-0.491-2.354-1.707c0-1.552,1.707-2.147,3.182-2.147c1.318,0,1.939,0.285,2.742,0.673
    		C185.53,44.692,183.927,46.012,182.192,46.012z M199.214,35.432l-3.441,8.718h-0.104l-3.568-8.718h-3.234l5.355,12.185
    		l-3.053,6.777h3.129l8.252-18.962H199.214z M172.183,48.296h3V28.188h-3V48.296z"/>
    	<path fill="#5BC9F4" d="M16.971,12.184c-0.468,0.494-0.744,1.262-0.744,2.258v35.541c0,0.996,0.276,1.764,0.744,2.258l0.119,0.116
    		L37,32.447v-0.235v-0.234l-19.91-19.91L16.971,12.184z"/>
    	<path fill="#F89737" d="M43.635,39.086L37,32.447v-0.235v-0.234l6.638-6.639l0.15,0.085l7.863,4.468
    		c2.246,1.276,2.246,3.364,0,4.641L43.787,39L43.635,39.086z"/>
    	<path fill="#EF3B39" d="M43.787,39L37,32.212L16.971,52.24c0.741,0.784,1.963,0.881,3.34,0.099L43.787,39"/>
    	<path fill="#6EC066" d="M43.787,25.424L20.311,12.085c-1.377-0.782-2.599-0.686-3.34,0.099L37,32.212L43.787,25.424z"/>
    	<path fill="#FFFFFF" d="M73.925,13.32c-0.604-0.535-1.4-0.879-2.279-0.879c-1.978,0-3.502,1.634-3.502,3.64
    		c0,2.005,1.524,3.625,3.502,3.625c1.703,0,2.938-0.988,3.021-2.555h-2.691v-1.112h4.147c0,3.324-1.771,4.89-4.477,4.89
    		c-2.692,0-4.779-2.17-4.779-4.848c0-2.692,2.087-4.862,4.779-4.862c1.25,0,2.375,0.481,3.2,1.25L73.925,13.32z"/>
    	<path fill="#FFFFFF" d="M83.472,11.329v1.194h-4.326v3.021h3.777v1.195h-3.777v2.856h4.463v1.194h-5.74v-9.462H83.472z"/>
    	<path fill="#FFFFFF" d="M91.753,11.329v1.208h-2.87v8.254h-1.291v-8.254h-2.871v-1.208H91.753z"/>
    	<path fill="#FFFFFF" d="M97.88,11.329v9.462h-1.277v-9.462H97.88z"/>
    	<path fill="#FFFFFF" d="M106.587,11.329v1.208h-2.87v8.254h-1.291v-8.254h-2.871v-1.208H106.587z"/>
    	<path fill="#FFFFFF" d="M115.598,11.219c2.705,0,4.807,2.17,4.807,4.862c0,2.678-2.102,4.848-4.807,4.848
    		c-2.691,0-4.779-2.17-4.779-4.848C110.819,13.389,112.907,11.219,115.598,11.219z M115.598,19.692c1.991,0,3.516-1.606,3.516-3.611
    		c0-2.006-1.524-3.626-3.516-3.626c-1.978,0-3.502,1.62-3.502,3.626C112.096,18.086,113.621,19.692,115.598,19.692z"/>
    	<path fill="#FFFFFF" d="M127.959,20.791l-4.312-7.127v7.127h-1.277v-9.462h1.264l4.271,7.072v-7.072h1.277v9.462H127.959z"/>
    </g>
  </SvgIcon>
)
