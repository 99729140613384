import React from 'react'
import MetaTags from 'react-meta-tags'
import parse from 'html-react-parser'

import { useLanguage } from '../hooks/useLang'

const AppMeta = () => {
  const language = useLanguage()

  return (
    <MetaTags>
      <meta charSet="utf-8" />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="robots" content="index, nofollow" />
      <title>{parse(language.languageVars.data.meta.title)}</title>
      <meta name="description" content={parse(language.languageVars.data.meta.description)} />
      <meta name="image" content="/assets/images/eltarot-image.jpg" />
      <meta itemProp="name" content={parse(language.languageVars.data.meta.title)} />
      <meta itemProp="description" content={parse(language.languageVars.data.meta.description)} />
      <meta itemProp="image" content="/assets/images/eltarot-image.jpg" />
      <meta property="og:site_name" content="El Tarot" />
      <meta property="og:title" content={parse(language.languageVars.data.meta.title)} />
      <meta property="og:description" content={parse(language.languageVars.data.meta.description)} />
      <meta property="og:image" content="/assets/images/eltarot-og.jpg" />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en_US" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={parse(language.languageVars.data.meta.title)} />
      <meta name="twitter:description" content={parse(language.languageVars.data.meta.description)} />
      <meta name="twitter:site" content="@eltarot" />
      <meta name="twitter:image:src" content="/assets/images/eltarot-tw.jpg" />
    </MetaTags>
  )
}

export default AppMeta
