import React from 'react'
import { Link } from 'react-router-dom'

import { Box } from '@material-ui/core'

import CookieConsent from 'react-cookie-consent'

import { cookieBarStyles } from './styles'

import { useLanguage } from '../hooks/useLang'

const CookieBar = () => {
  const classes = cookieBarStyles()
  const language = useLanguage()

  return (
    <Box className={classes.container} dir={language.direction}>
      <CookieConsent
        location="bottom"
        buttonText={language.languageVars.messages.cookieBar.button}
        cookieName="disclaimerCookie"
        style={{ background: '#000' }}
        buttonStyle={{ backgroundColor: '#f3f0ea', fontSize: '13px' }}
        expires={150}
      >
        {language.languageVars.messages.cookieBar.message}
        &nbsp;
        <Link to="/privacy" className={classes.link}>{language.languageVars.messages.cookieBar.link}</Link>
      </CookieConsent>
    </Box>
  )
}

export default CookieBar
