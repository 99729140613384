import React, {
  useEffect, useState, lazy, Suspense,
} from 'react'
import { useLocation, useHistory, Redirect } from 'react-router-dom'
import queryString from 'query-string'

import { Box } from '@material-ui/core'

import LoadingBackdrop from '../../components/Loading/LoadingBackdrop'

import { firebaseApp } from '../../API/firebase'
import { useAuth } from '../../hooks/use-auth'
import { useLanguage } from '../../hooks/useLang'

const ConfirmEmailStatus = lazy(() => import('../../components/Auth/ConfirmEmailStatus'))

const AuthVerified = () => {
  const auth = useAuth()
  const location = useLocation()
  const history = useHistory()
  const language = useLanguage()
  const { oobCode } = queryString.parse(location.search)
  const [validOobCode, setValidOobCode] = useState('processing')

  useEffect(() => {
    let mounted = true;

    (async () => {
      if (auth.user) {
        try {
          if (oobCode && !auth.emailVerified) {
            const checkedOobCode = await firebaseApp.auth().checkActionCode(oobCode)
            if (mounted) {
              if (auth.user.email === checkedOobCode.data.email) {
                await firebaseApp.auth().applyActionCode(oobCode)
                await firebaseApp.auth().currentUser.reload()
                auth.confirmLogin()
                setValidOobCode('valid')
              } else {
                setValidOobCode('invalid')
              }
            }
          }
        } catch (err) {
          setValidOobCode('invalid')
          throw new Error(err)
        }
      }
    })()

    return () => { mounted = false }
  }, [auth, history, oobCode])

  if (!oobCode) {
    return <Redirect to="/" />
  }

  return (
    <>
      {validOobCode === 'processing' ? (
        <LoadingBackdrop loadingText={language.languageVars.loadingMessages.validatingEmail} />
      ) : (
        <Box>
          <Suspense fallback="Loading...">
            <ConfirmEmailStatus validStatus={validOobCode} />
          </Suspense>
        </Box>
      )}
    </>
  )
}

export default AuthVerified
