import React, {
  useEffect, useState, lazy, Suspense,
} from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Box, Button } from '@material-ui/core'

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

import { createFormElementObj, adjustFormValues, createFormValuesObj } from '../../utilities/form'

import { useAuth } from '../../hooks/use-auth'
import { useLanguage } from '../../hooks/useLang'

import { getUserById } from '../../API/users'

import * as actions from '../../store/actions'

import LoadingBackdrop from '../../components/Loading/LoadingBackdrop'

const MyReadsList = lazy(() => import('../../components/MyReads/MyReadsList'))
const UserPref = lazy(() => import('../../components/MyReads/UserPref'))
const PageTitle = lazy(() => import('../../layout/PageTitle'))
const Reader = lazy(() => import('../Reader/Reader'))

const MyReads = ({
  savedReads,
  loading,
  loadingUserPref,
  onLoadMyReads,
  onRemoveRead,
  onLoadRead,
  onSetNotification,
  processingRead,
  onClearReader,
  onLoadUserPref,
  onAddUserPref,
}) => {
  const auth = useAuth()
  const language = useLanguage()
  const [loadingStarted, setLoadingStarted] = useState(true)
  const [readStart, setReadStart] = useState(false)
  const [userPrefValid, setUserPrefValid] = useState(false)
  const [userPref, setUserPref] = useState({
    readerName: createFormElementObj('input', language.languageVars.data.spread.form.readerName,
      { type: 'text', name: 'reader_name', placeholder: language.languageVars.data.spread.form.readerName }, '', null, { required: true }, false,
      {
        xs: 12,
        sm: null,
        md: null,
        lg: null,
        xl: null,
        fullWidth: true,
      }),
    readerBirthDate: createFormElementObj('date', language.languageVars.data.spread.form.readerBirthDate,
      { type: 'text', name: 'reader_birth_date', placeholder: language.languageVars.data.spread.form.readerBirthDate }, null, null, { required: false }, false,
      {
        xs: 12,
        sm: null,
        md: null,
        lg: null,
        xl: null,
        fullWidth: true,
      }),
  })

  const loadingProgress = processingRead

  useEffect(() => {
    let mounted = true

    if (mounted) {
      (async () => {
        await onLoadUserPref(auth.user.uid)
        const data = await getUserById(auth.user.uid)
        const adjustedForm = await adjustFormValues(userPref, data, null)
        setUserPref(prevForm => ({ ...prevForm, ...adjustedForm.adjustedForm }))
        setUserPrefValid(adjustedForm.formValid)
        await onLoadMyReads(auth.user.uid)
        setLoadingStarted(false)
        onClearReader()
      })()
    }

    return () => { mounted = false }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user.uid, onClearReader, onLoadMyReads])

  const removeReadHandler = async read => {
    try {
      await onRemoveRead(savedReads, auth.user.uid, read)
      onSetNotification({
        message: language.languageVars.notifications.readRemovedSuccess,
        type: 'success',
      })
    } catch (err) {
      onSetNotification({
        message: language.languageVars.notifications.readRemovedFail,
        type: 'error',
      })
    }
  }

  const loadReadHandler = async readDate => {
    try {
      await onLoadRead(auth.user.uid, readDate)
      setReadStart(true)
    } catch (err) {
      onSetNotification({
        message: language.languageVars.notifications.readLoadFail,
        type: 'error',
      })
    }
  }

  const backToListHandler = () => {
    setReadStart(false)
    onClearReader()
  }

  const inputChangeHandler = (e, key) => {
    let changeEvent

    if (Array.isArray(e)) {
      changeEvent = e.join()
    } else if (Number.isInteger(e)) {
      changeEvent = String(e)
    } else {
      changeEvent = e
    }
    const adjustedForm = adjustFormValues(userPref, changeEvent, key)
    setUserPref(adjustedForm.adjustedForm)
    setUserPrefValid(adjustedForm.formValid)
  }

  const updateUserPrefHandler = async e => {
    e.preventDefault()
    const userPrefDetails = createFormValuesObj(userPref)
    try {
      onAddUserPref(auth.user.uid, userPrefDetails.readerName, userPrefDetails.readerBirthDate)
    } catch (err) {
      onSetNotification({
        message: language.languageVars.notifications.userPrefFail,
        type: 'error',
      })
    }
  }

  if (readStart) {
    return (
      <>
        <Box align="center" mt={4}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            startIcon={<ArrowBackIosIcon />}
            onClick={() => backToListHandler()}
          >
            {language.languageVars.buttons.myReads.backToSavedReads}
          </Button>
        </Box>
        <Suspense fallback="Loading...">
          <Reader />
        </Suspense>
        <Box align="center">
          <Button
            variant="outlined"
            color="primary"
            size="small"
            startIcon={<ArrowBackIosIcon />}
            onClick={() => backToListHandler()}
          >
            {language.languageVars.buttons.myReads.backToSavedReads}
          </Button>
        </Box>
      </>
    )
  }

  return (
    <Box>
      {loadingProgress && <LoadingBackdrop loadingText={language.languageVars.loadingMessages.loadingSavedReads} />}
      <Suspense fallback="Loading...">
        <PageTitle
          title={language.languageVars.titles.myAccount.myPref.title}
        />
      </Suspense>
      <Suspense fallback="Loading...">
        <UserPref
          pref={userPref}
          valid={userPrefValid}
          loading={loadingUserPref}
          onChange={inputChangeHandler}
          onSubmit={updateUserPrefHandler}
        />
      </Suspense>
      <Suspense fallback="Loading...">
        <PageTitle
          title={language.languageVars.titles.myAccount.myReads.title}
          info={!loadingStarted && (savedReads && savedReads.length > 0 ? language.languageVars.titles.myAccount.myReads.titleInfoWithReads : language.languageVars.titles.myAccount.myReads.titleInfoWithoutReads)}
        />
      </Suspense>
      <Suspense fallback="Loading...">
        <MyReadsList reads={savedReads} loading={loading} removeRead={removeReadHandler} loadRead={loadReadHandler} />
      </Suspense>
    </Box>
  )
}

MyReads.defaultProps = {
  loading: false,
  savedReads: null,
  processingRead: false,
  loadingUserPref: false,
}

MyReads.propTypes = {
  savedReads: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.object,
  ]))),
  loading: PropTypes.bool,
  loadingUserPref: PropTypes.bool,
  onLoadMyReads: PropTypes.func.isRequired,
  onRemoveRead: PropTypes.func.isRequired,
  onLoadRead: PropTypes.func.isRequired,
  onSetNotification: PropTypes.func.isRequired,
  processingRead: PropTypes.bool,
  onClearReader: PropTypes.func.isRequired,
  onLoadUserPref: PropTypes.func.isRequired,
  onAddUserPref: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  savedReads: state.myReads.reads,
  loading: state.myReads.loading,
  loadingUserPref: state.myReads.loadingUserPref,
  processingRead: state.reader.loading,
})

const mapDispatchToProps = dispatch => ({
  onLoadMyReads: userId => dispatch(actions.loadMyReads(userId)),
  onRemoveRead: (currentReads, userId, readObj) => dispatch(actions.removeOneRead(currentReads, userId, readObj)),
  onLoadRead: (userId, readDate) => dispatch(actions.loadSavedRead(userId, readDate)),
  onSetNotification: notification => dispatch(actions.setNotification(notification)),
  onClearReader: () => dispatch(actions.clearReader()),
  onLoadUserPref: userId => dispatch(actions.loadUserPref(userId)),
  onAddUserPref: (userId, userName, userBirthDate) => dispatch(actions.changeUserPref(userId, userName, userBirthDate)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MyReads)
