export const en = {
  language: 'العربية',
  languageShort: 'ع',
  appName: 'El Tarot',
  login: 'Login',
  cards: 'cards',
  oneCard: '1 more card',
  read: 'Read',
  customRead: 'Custom Read',
  savedRead: 'Saved Read',
  reversed: 'reversed',
  titles: {
    privacy: {
      title: 'Privacy Policy',
    },
    landing: {
      hero: 'Card meanings<br />Tarot Readings &amp;<br />Customized readings',
      learn: 'Learn Tarot through custom readings',
      cardsCarousel: 'Learn cards meanings',
      heroOne: 'Learn Tarot reading & discover more about yourself',
      cardsBanner: 'Learn taot cards meanings',
      blogPosts: 'From Eltarot blog',
    },
    auth: {
      title: 'Sign-up / Login',
      titleInfo: 'Choose one of the methods below to authenticate',
    },
    reads: {
      title: {
        read: 'Select Read',
        customRead: 'Select Custom Read',
      },
      titleInfo: 'Select one of the read types below',
    },
    myAccount: {
      myPref: {
        title: 'My Preferences',
      },
      myReads: {
        title: 'Saved Reads',
        titleInfoWithReads: 'Click any read in list to load.',
        titleInfoWithoutReads: 'There is no saved reads at the moment.',
      },
    },
    reader: {
      advice: {
        title: {
          oneCard: 'The answer',
          twoCards: 'The choice',
          general: 'Advices',
        },
      },
    },
    history: {
      title: 'A Brief History of Tarot',
      titleInfo: 'More for entertainment than divination',
    },
    learn: {
      title: 'Tarot can help',
      titleInfo: 'Tarot can be a powerful tool to help with mindfulness, self-discovery, and self-awareness.'
      + '&nbsp;You need to relate the explanation of the cards to your past, everyday life, and experiences and understand how they may apply',
    },
    philosophy: {
      title: 'Our Tarot Philosophy',
      titleInfo: 'Create Your Magical World',
    },
    support: {
      subscribed: {
        title: 'Thank you',
        titleInfo: 'Your support means a lot to us and to the Tarot community. We will do our best to maintain a good service and add more features to ElTarot.',
      },
      notSubscribed: {
        title: 'Support us',
        titleInfo: 'Your support will help us maintain and add more features to ElTarot. And we will stop showing ads in return ;)',
      },
    },
    customRead: 'Custom Read',
    editCard: 'Edit Card',
    paymentFormTitle: 'Card details',
    playStore: 'Available on Play Store',
  },
  data: {
    meta: {
      title: 'Tarot cards meaning<br />Tarot Readings &amp;<br />Customized readings',
      description: 'Tarot is a powerful tool to help with mindfulness and self-discovery.'
      + '&nbsp;There’s nothing mystical about them, they simply help us unlock our thoughts and organize them in ways that make sense and help us live our best possible lives.',
    },
    landing: {
      heroText: 'Tarot is a powerful tool to help with mindfulness and self-discovery.'
      + '&nbsp;There’s nothing mystical about them, they simply help us unlock our thoughts and organize them in ways that make sense and help us live our best possible lives.',
      learnText: 'Create readings with any combination you have in mind to learn about different layouts and meanings or use it to translate readings created by your own deck of cards.',
      cardsBanner: 'A detailed search to help you find the meaning of any card and learn the arrangement of tarot suits.',
      blogPosts: [
        {
          title: 'Major Arcana: The fool’s journey',
          description: 'The major arcana tells human perceptions through iconoclastic expression, numerology, and elementary symbolism.',
          image: 'fools-journey',
          link: 'https://www.eltarot.blog/post/tarot-major-arcana-the-fool-s-journey?lang=en',
        },
        {
          title: 'Tarot cards connection to zodiac signs',
          description: 'The four suits of tarot correspond with the four elements of life in astrology.',
          image: 'zodiac',
          link: 'https://www.eltarot.blog/post/what-tarot-cards-represent-which-zodiac-signs-1?lang=en',
        },
        {
          title: 'Tarot - Minor Arcana: The suit of cups',
          description: 'The suit represents the friendship and love of one’s fellow humans.',
          image: 'cups',
          link: 'https://www.eltarot.blog/post/tarot-minor-arcana-the-suit-of-cups-1?lang=en',
        },
        {
          title: 'Tarot - Minor Arcana: The suit of swords',
          description: 'The suit represents the alienation that comes from cutting yourself off from your inner self.',
          image: 'swords',
          link: 'https://www.eltarot.blog/post/tarot-minor-arcana-the-suit-of-swords-1?lang=en',
        },
        {
          title: 'Tarot - Minor Arcana: The suit of wands',
          description: 'The suit identifies behaviors, skills, and circumstances that can be a potential for success.',
          image: 'wands',
          link: 'https://www.eltarot.blog/post/tarot-minor-arcana-the-suit-of-wands-1?lang=en',
        },
        {
          title: 'Tarot - Minor Arcana: The suit of pentacles',
          description: 'The suit deal with material resources, physical capabilities, or health conditions.',
          image: 'pentacles',
          link: 'https://www.eltarot.blog/post/tarot-minor-arcana-the-suit-of-pentacles-1?lang=en',
        },
      ],
    },
    customRead: {
      cardBoxText: 'Click to select a card.',
      selectDialogueTitle: {
        text1: 'Select card for',
        text2: 'slot',
      },
    },
    history: {
      section1: {
        title: 'Tarot as Divination',
        paragraph: 'The original purpose of Tarot was as a parlor game, not as a divinatory tool.'
        + '&nbsp;It appears that divination with playing cards started to become popular in the late sixteenth and early seventeenth century,'
        + '&nbsp;although at that time, it was far more simple than the way we use Tarot today.'
        + '<br />'
        + 'By the eighteenth century, however, people were beginning to assign specific meanings to each card, and even offer suggestions as to how they could be laid out for divinatory purposes.',
      },
      section2: {
        title: 'Ancient Egypt',
        paragraph: 'A complex published by Antoine Court de Gebelin revealed that the symbolism in the Tarot was in fact derived from the esoteric secrets of Egyptian priests.'
        + '&nbsp;De Gebelin went on to explain that this ancient occult knowledge had been carried to Rome and revealed to the Catholic Church and the popes,'
        + 'who desperately wanted to keep this arcane knowledge secret.'
        + '<br />'
        + 'In his essay, the chapter on Tarot meanings explains the detailed symbolism of Tarot artwork and connects it to the legends of Isis, Osiris and other Egyptian gods.'
        + '<br />'
        + 'However, this concept is considered as pseudo histories by many.',
      },
      section3: {
        title: 'Tarot world wide',
        paragraph: 'The ancestors of what we today know as Tarot cards can be traced back to around the late fourteenth century.'
        + '&nbsp;Artists in Europe created the first playing cards, which were used for games, and featured four different suits.'
        + 'These suits were similar to what we still use today – staves or wands, discs or coins, cups, and swords.'
        + '<br />'
        + 'After a decade or two of using these, in the mid-1400s, Italian artists began painting additional cards, heavily illustrated, to add into the existing suits.',
      },
      section4: {
        title: 'Rider-Waite',
        paragraph: 'British occultist Arthur Waite was a member of the Order of the Golden Dawn – and apparently a longtime nemesis of Aleister Crowley,'
        + 'who was also involved in the group and its various offshoots.'
        + 'Waite got together with artist Pamela Colman Smith, also a Golden Dawn member, and created the Rider-Waite Tarot deck, which was first published in 1909.'
        + '<br />'
        + 'At Waite&apos;s suggestion, Smith used the Sola Busca artwork for inspiration, and there are many similarities in the'
        + 'symbolism between Sola Busca and Smith&apos;s final result. Smith was the first artist to use characters as representative images in the lower cards.'
        + 'Instead of showing merely a cluster of cups, coins, wands or swords, Smith incorporated human figures into the artwork, and the result is the iconic deck that every reader knows today.',
      },
    },
    learn: {
      section1: {
        title: 'The deck',
        paragraph: 'The traditional tarot deck is made up of 78 cards: 22 Major Arcana cards, 40 Minor Arcana cards, and 16 Court cards.'
        + '<br />'
        + 'Each card has a general meaning attached to it, but the interpretation of the card shifts depending on the position of the card in the layout and its interaction with the other cards.',
      },
      section2: {
        title: 'The readings',
        paragraph: 'Readings are often done by laying out three cards in a row, or alternately five in a cross shape.'
        + '<br />'
        + 'Readings can also be done with or without allowance of reversing cards (upside down), and it will lead to an alternate interpretation.'
        + '&nbsp;Reversed cards often have a more negative connotation, but not necessarily a pessimistic one, often meaning reversal of the ordinary meanings.',
      },
      section3: {
        title: 'The cards',
        subTitle1: 'Major Arcana',
        paragraph1: 'The Major Arcana is a suit of 22 cards in the 78-card Tarot deck.'
        + '<br />'
        + 'They serve as the main suits in games played with the Tarot deck and are different from the four standard suits known as the Minor Arcana.'
        + '<br />'
        + 'The symbols are basically the same in all decks, even though they may differ thematically according to the philosophy of the designer.'
        + '<br />'
        + 'Each of the Major Arcana cards has a title, such as The Magician, The Empress, The Lovers, The Moon, The Tower, and so forth.'
        + '&nbsp;They are numbered from zero—The Fool—to twenty-one—The World.',
        subTitle2: 'Minor Arcana',
        paragraph2: 'The Minor Arcana are the 56 suit cards of the 78-card deck of tarot playing cards.'
        + '<br />'
        + 'They are divided into four suits with 14 cards each. The included suits are: Wands, cups, swords, and pentacles.'
        + '<br />'
        + 'Each suit contains four Court Cards (King, Queen, Knight, and Page) and ten number cards from Ace through Ten, also called pip cards.',
      },
    },
    philosophy: {
      quote: 'Just as a candle cannot burn without fire, man cannot live without a spiritual life.',
      quoter: 'Buddha',
      section1: {
        title: 'Learn Tarot and learn to control your fate.',
        paragraph: 'We are not trying to tell your fortune or future and we don&apos;t believe in divination.'
        + '<br />'
        + 'We believe that the most powerful sources of information come from within and the Tarot aids in coming in contact with one&apos;s Higher Self'
        + 'We only offer the experience that can help you build a connection to yourself and have more empathy with the world around you.',
      },
      section2: {
        title: 'Believe the magic within your story',
        paragraph: 'We believe that everyone can develop a better self-knowledge if more attention is given to her/his daily actions and behaviours.'
        + '<br />'
        + 'When we&apos;re studying a card and trying to assign it meaning in our lives, we are tapping into the parts of our brains that are usually hidden.'
        + '<br />'
        + 'Through the signs and symbols, you can read the reflections of your inner self and this is important for self-development.',
      },
      section3: {
        title: 'You can predict the future',
        paragraph: '&quot;We can predict the future, when we know how the present moment evolved from the past.'
        + '<br />'
        + 'Tarot can be an intuitive method that has the purpose of understanding the flow of life, possibly even predicting future events,'
        + 'at all events lending itself to the reading of the conditions of the present moment.&quot;'
        + '<br />'
        + '<b>Carl Jung</b>',
      },
    },
    support: {
      paragraph1: 'If you are facing any issues or have suggestions please don&apos;t hesitate to contact us',
      paragraph2: 'Only one payment for $3',
      paragraph3: 'Powered by',
    },
    cardDetails: {
      uprightKeywords: 'Upright Keywords',
      reversedKeywords: 'Reversed Keywords',
      description: 'Description',
      uprightMeaning: 'Upright Meaning',
      reversedMeaning: 'Reversed Meaning',
      keywords: 'Keywords',
      meaning: 'Meaning',
      advice: 'Advice',
    },
    filterBox: {
      filter: 'Filter',
      all: 'All',
    },
    searchField: {
      placeholder: 'Search Cards',
    },
    cardHoverText: {
      text1: '-- Click to select >',
      text2: '-- Details >',
    },
    privacy: {
      p1: 'At Eltarot, accessible from http://eltarot.app, one of our main priorities is the privacy of our visitors.'
      + 'This Privacy Policy document contains types of information that is collected and recorded by Eltarot and how we use it.',
      p2: 'If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.',
      p3: 'This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Eltarot.'
      + 'This policy is not applicable to any information collected offline or via channels other than this website.',
      t1: 'Consent',
      p4: 'By using our website, you hereby consent to our Privacy Policy and agree to its terms.',
      t2: 'Information we collect',
      p5: 'The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.',
      p6: 'If you contact us directly, we may receive additional information about you such as your name, email address, '
      + 'the contents of the message and/or attachments you may send us, and any other information you may choose to provide.',
      p7: 'When you register for an Account, we may ask for your contact information, including items such as name and email address.',
      t3: 'How we use your information',
      p8: 'We use the information we collect in various ways, including to:',
      l1: 'Provide, operate, and maintain our webapp',
      l2: 'Improve, personalize, and expand our webapp',
      l3: 'Understand and analyze how you use our webapp',
      l4: 'Develop new products, services, features, and functionality',
      l5: 'Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other '
      + 'information relating to the webapp, and for marketing and promotional purposes',
      l6: 'Send you emails',
      l7: 'Find and prevent fraud',
      t4: 'Log Files',
      p9: 'Eltarot follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part '
      + 'of hosting services analytics. The information collected by log files include internet protocol (IP) addresses, browser type, '
      + 'Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. '
      + 'These are not linked to any information that is personally identifiable. '
      + 'The purpose of the information is for analyzing trends, administering the site, tracking users movement on the website, and gathering demographic information.',
      t5: 'Cookies and Web Beacons',
      p10: 'Like any other website, Eltarot uses cookies. These cookies are used to store information including visitors'
      + 'preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users experience by '
      + 'customizing our web page content based on visitors browser type and/or other information.',
      p11: {
        text: 'For more general information on cookies, please read',
        link: '"What Are Cookies" from Cookie Consent',
      },
      t6: 'Google DoubleClick DART Cookie',
      p12: {
        text: 'Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon '
        + 'their visit to www.eltarot.app and other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following URL – ',
        link: 'https://policies.google.com/technologies/ads',
      },
      t7: 'Our Advertising Partners',
      p13: 'Some of advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has '
      + 'their own Privacy Policy for their policies on user data. For easier access, we hyperlinked to their Privacy Policies below.',
      p14: 'Google',
      p15: {
        link: 'https://policies.google.com/technologies/ads',
      },
      t8: 'Advertising Partners Privacy Policies',
      p16: 'You may consult this list to find the Privacy Policy for each of the advertising partners of Eltarot.',
      p17: 'Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and '
      + 'links that appear on Eltarot, which are sent directly to users browser. They automatically receive your IP address when this occurs. '
      + 'These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.',
      p18: 'Note that Eltarot has no access to or control over these cookies that are used by third-party advertisers.',
      t9: 'Third Party Privacy Policies',
      p19: 'Eltarot Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these '
      + 'third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.',
      p20: 'You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management '
      + 'with specific web browsers, it can be found at the browsers respective websites.',
      t10: 'CCPA Privacy Rights (Do Not Sell My Personal Information)',
      p21: 'Under the CCPA, among other rights, California consumers have the right to:',
      p22: 'Request that a business that collects a consumer personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.',
      p23: 'Request that a business delete any personal data about the consumer that a business has collected.',
      p24: 'Request that a business that sells a consumer personal data, not sell the consumer personal data.',
      p25: 'If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.',
      t11: 'GDPR Data Protection Rights',
      p26: 'We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:',
      p27: 'The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.',
      p28: 'The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right '
      + 'to request that we complete the information you believe is incomplete.',
      p29: 'The right to erasure – You have the right to request that we erase your personal data, under certain conditions.',
      p30: 'The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.',
      p31: 'The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.',
      p32: 'The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.',
      p33: 'If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.',
      t12: 'Children Information',
      p34: 'Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.',
      p35: 'Eltarot does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child '
      + 'provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.',
    },
    spread: {
      form: {
        readerName: 'Reader Name',
        readerBirthDate: 'Reader Birth Date',
        readSubject: 'Subject',
        readSubjectList: ['General', 'Love & Relationships', 'Work & Finances'],
        readQuestion: 'Question',
        enableReversed: 'Enabled reversed cards',
      },
    },
    reader: {
      readInfo: {
        readDate: 'Read date',
        readerName: 'Reader name',
        readerBirthDate: 'Reader birth date',
        readSubject: 'Read subject',
        readQuestion: 'Read question',
      },
      answer: {
        remember: 'Always remember that the best way to answer you questions is rational thinking.',
        oneCard: {
          yes: 'Reading tends to answer yes',
          no: 'Reading tends to answer no',
        },
        twoCards: {
          opt1: 'Reading is closer to the first choice',
          opt2: 'Reading is closer to the second choice',
        },
      },
    },
  },
  buttons: {
    landing: {
      startNormalRead: 'Start a normal read',
      startCustomRead: 'Start a custom read',
      startReading: 'Start Reading',
      androidVersion: 'Google play',
      allCards: 'Show Cards',
      blogPost: 'Read more',
      allPosts: 'All Posts',
      customRead: 'Try now',
    },
    myReads: {
      backToSavedReads: 'Back to saved reads',
    },
    editCard: 'Update Card',
    saveRead: 'Save read',
    spread: 'Spread',
    shuffle: 'Shuffle',
    enableReversed: 'Enabled reversed cards',
    resendVerificationEmail: 'Click here to resend verification email',
    cardFullDetails: 'Full Details',
    support: 'Support',
    start: 'Start',
    save: 'Save',
  },
  links: {
    allPosts: 'https://www.eltarot.blog/the-review?lang=en',
    playStoreListing: 'https://play.google.com/store/apps/details?id=com.t2madrid.Eltarot',
  },
  notifications: {
    updateCardSuccess: 'Card updated successfuly',
    updateCardFail: 'Unable to update card. An error occurred.',
    readSaveLimit: 'You have exceeded the maximum saved reads. Please remove at least one from your saved reads to be able to continue',
    readSaveSuccess: 'Read successfuly saved.',
    readSaveFail: 'Failed to save read. An error occurred',
    readLoadFail: 'Failed to load read. Please check your connection and try again.',
    userPrefFail: 'Failed to load user preferences',
    readRemovedSuccess: 'Read removed successfuly.',
    readRemovedFail: 'There was a problem removing this read. Please try again.',
    subscriptionPaymentFail: 'There was a problem accepting payment. Please try again later.',
    subscriptionPaymentSuccess: 'Your Subscription is successfull. Happy reading :)',
    clientOffline: 'You appear to be offline. You can still use Eltarot but it is recommnded to use with internet connection for a better experience.',
  },
  messages: {
    readPref: 'Add the required info to start the read',
    cardsNotShuffled: 'Relax and click the cards to shuffle',
    cardsNotSpread: 'Click the deck to spread.',
    selectCards: {
      select: 'Select ',
      cardsToContinue: ' cards to continue',
      toContinue: ' to continue',
    },
    flipCards: 'Click all cards to reveal the meaning',
    emailNotVerified: '<b>Please verify your email address</b>'
    + '<br />'
    + 'The email account provided is not verified',
    verifyEmail: '<b>Please verify your email address</b>'
    + '<br />'
    + 'A verification email has been sent to your email address. Please follow the instructions in the email to continue using the app.',
    verificationSuccess: '<b>Your email address was successfully verified</b>'
    + '<br />'
    + 'You can start using Eltarot. Happy reading :)',
    verificationFail: '<b>There was a problem verifying your email address.</b>'
    + '<br />'
    + 'Click the link below to resend verification email.',
    reader: {
      advice: {
        general: 'Flip all cards to view the read advices.',
        oneCard: 'Flip all cards to view the answer.',
        twoCards: 'Flip all cards to view the prefered choice',
      },
    },
    cookieBar: {
      message: 'This website uses cookies to enhance the user experience.',
      link: 'Privacy policy',
      button: 'Agree',
    },
    validationErrors: {
      required: {
        text1: 'This field is required',
        text2: '',
      },
      maxLen: {
        text1: 'Max length is',
        text2: 'characters',
      },
      minLen: {
        text1: 'Min length is',
        text2: 'characters',
      },
      email: {
        text1: 'Please provid a valid email address',
        text2: '',
      },
      numbers: {
        text1: 'Only numbers are allowed',
        text2: '',
      },
      match: {
        text1: 'Must match',
        text2: 'field',
      },
    },
  },
  loadingMessages: {
    loadingCustomReadSlots: 'Organizing read slots',
    loadingSavedReads: 'Processing',
    savingRead: 'Saving Read...',
    loadingCardDetails: 'Loading details...',
    validatingEmail: 'Validating your email ...',
  },
  navLinks: {
    homePage: 'Home',
    cardsList: 'Cards',
    readsList: 'Read',
    customRead: 'Custom Read',
    authintication: 'Sign-in',
    logout: 'Logout',
  },
  footerLinks: {
    philosophy: 'Philosophy',
    history: 'History',
    learn: 'Learn',
    privacy: 'Privacy Policy',
    contact: 'Contact',
  },
  userLinks: {
    myReads: 'My Account',
    logout: 'Logout',
    users: 'Users',
  },
  suits: {
    all: 'All',
    major: 'major',
    cups: 'cups',
    pentacles: 'pentacles',
    wands: 'wands',
    swords: 'swords',
  },
  fonts: {
    cardTitle: {
      family: 'Berkshire Swash',
    },
  },
}
