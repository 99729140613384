import React, {
  useState, lazy, Suspense,
} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'

import {
  Button, Grid, Box, Typography, List, ListItem, ListItemText,
} from '@material-ui/core'

import { Element, scroller } from 'react-scroll'

import LoadingBackdrop from '../../components/Loading/LoadingBackdrop'
import PageTitle from '../../layout/PageTitle'

import { saveRead, getSavedReads } from '../../API/users'
import { useAuth } from '../../hooks/use-auth'
import { useLanguage } from '../../hooks/useLang'

import { reader } from './styles'

import * as vars from '../../utilities/appVars'

import * as actions from '../../store/actions'

const ReadingCard = lazy(() => import('../../components/Reader/ReadingCard'))
const MeaningSection = lazy(() => import('../../components/Reader/MeaningSection'))
const DetailsDialog = lazy(() => import('../../components/Cards/DetailsDialog'))
const SubscribeDialog = lazy(() => import('../../components/SupportUs/SubscribeDialog'))
const ReadInfo = lazy(() => import('../../components/Reader/ReadInfo'))
const PlayStoreBanner = lazy(() => import('../../layout/PlayStoreBanner'))

const Reader = ({
  maxSelectedCards,
  numberOfFlippedCards,
  readSetup,
  createdRead,
  readType,
  readTitle,
  readTitleAr,
  readerName,
  readerBirthDate,
  readSubject,
  readQuestion,
  isCustomRead,
  isLoadedRead,
  onSetNotification,
  onFlipCard,
  readDate,
  onOpenSession,
  isSubscriberSessionOpen,
}) => {
  const classes = reader()
  const auth = useAuth()
  const language = useLanguage()

  const [dialogOpen, setDialogOpen] = useState(false)
  const [subscibeDialogOpen, setSubscibeDialogOpen] = useState(false)
  const [cardInfo, setCardInfo] = useState(null)
  const [saving, setSaving] = useState(false)
  const [readSaved, setReadSaved] = useState(false)

  const history = useHistory()
  const location = useLocation()
  const { apptype } = queryString.parse(location.search)
  const blogRead = apptype && apptype === 'blog'

  const readTitleName = language.direction === 'rtl' ? readTitleAr : readTitle
  let readAdvicetitle = language.languageVars.titles.reader.advice.title.general
  let readAdviceMessage = language.languageVars.messages.reader.advice.general

  if (readType === 'one_card') {
    readAdvicetitle = language.languageVars.titles.reader.advice.title.oneCard
    readAdviceMessage = language.languageVars.messages.reader.advice.oneCard
  } else if (readType === 'two_cards') {
    readAdvicetitle = language.languageVars.titles.reader.advice.title.twoCards
    readAdviceMessage = language.languageVars.messages.reader.advice.twoCards
  }

  const pageTitle = () => {
    if (isLoadedRead) {
      return language.languageVars.savedRead
    }
    if (isCustomRead) {
      return language.languageVars.customRead
    }
    return language.languageVars.read
  }

  const openDetailsDialogHandler = info => {
    setDialogOpen(true)
    setCardInfo(info)
  }

  const closeDialogHandler = () => {
    setDialogOpen(false)
  }

  const closeSubscribeDialogHandler = () => {
    setSubscibeDialogOpen(false)
  }

  const flipCardHandler = cardIndex => {
    onFlipCard(cardIndex)
    if (numberOfFlippedCards + 1 === maxSelectedCards) {
      scroller.scrollTo('meaningContainer', {
        duration: 1000,
        delay: 100,
        smooth: true,
      })
    }
  }

  const setupReadCards = () => {
    let cardsCounter = 0
    let listedCard
    const readRows = readSetup.map(
      row => Object.entries(row).map(
        (col, j) => {
          listedCard = createdRead[cardsCounter]
          cardsCounter += 1
          return (
            <Grid item xs={12 / Object.keys(row).length} key={j} className={classes.gridItem}>
              <Suspense fallback="Loading...">
                <ReadingCard
                  card={listedCard}
                  onFlip={flipCardHandler}
                  isReversed={listedCard.isReversed}
                  position={language.direction === 'rtl' ? col[1].nameAr : col[1].name}
                />
              </Suspense>
            </Grid>
          )
        },
      ),
    )

    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="row"
        spacing={3}
        className={classes.gridContainer}
        dir={language.direction}
      >
        {readRows}
      </Grid>
    )
  }

  const setupReadMeaning = () => {
    let cardsCounter = 0
    let listedCard
    const readRows = readSetup.map(
      row => Object.entries(row).map(
        (col, j) => {
          listedCard = createdRead[cardsCounter]
          cardsCounter += 1
          return (
            <Grid item xs={12} key={j}>
              <Suspense fallback="Loading...">
                <MeaningSection
                  card={listedCard}
                  position={language.direction === 'rtl' ? col[1].nameAr : col[1].name}
                  positionDescription={language.direction === 'rtl' ? col[1].descriptionAr : col[1].description}
                  showDetails={openDetailsDialogHandler}
                />
              </Suspense>
            </Grid>
          )
        },
      ),
    )

    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="row"
        spacing={3}
        className={classes.meaningGridContainer}
      >
        {readRows}
      </Grid>
    )
  }

  const setupReadAdvice = () => {
    let readRows

    if (readType === 'one_card') {
      const cardIndex = Number(readSetup[0][0].lastDroppedItem.positivityIndex)
      readRows = (
        <ListItem alignItems="flex-start" dir={language.direction}>
          <ListItemText
            primary={` - ${cardIndex > 50 ? language.languageVars.data.reader.answer.oneCard.yes : language.languageVars.data.reader.answer.oneCard.no}`}
            style={language.direction === 'rtl' ? { textAlign: 'right' } : { textAlign: 'left' }}
            className={language.direction === 'rtl' ? classes.arabicFont : ''}
          />
        </ListItem>
      )
    } else if (readType === 'two_cards') {
      const optionOneIndex = Number(readSetup[0][0].lastDroppedItem.positivityIndex)
      const optionTwoIndex = Number(readSetup[0][1].lastDroppedItem.positivityIndex)
      readRows = (
        <ListItem alignItems="flex-start" dir={language.direction}>
          <ListItemText
            primary={` - ${optionOneIndex > optionTwoIndex ? language.languageVars.data.reader.answer.twoCards.opt1 : language.languageVars.data.reader.answer.twoCards.opt2}`}
            style={language.direction === 'rtl' ? { textAlign: 'right' } : { textAlign: 'left' }}
            className={language.direction === 'rtl' ? classes.arabicFont : ''}
          />
        </ListItem>
      )
    } else {
      readRows = readSetup.map(
        row => Object.entries(row).map(
          (col, j) => {
            if (col[1].takeAdvice) {
              return (
                <ListItem alignItems="flex-start" key={j} dir={language.direction}>
                  <ListItemText
                    primary={` - ${language.direction === 'rtl' ? col[1].lastDroppedItem.adviceAr : col[1].lastDroppedItem.advice}`}
                    style={language.direction === 'rtl' ? { textAlign: 'right' } : { textAlign: 'left' }}
                    className={language.direction === 'rtl' ? classes.arabicFont : ''}
                  />
                </ListItem>
              )
            }
            return false
          },
        ),
      )
    }

    return (
      <List className={classes.root} dir={language.direction}>
        {readRows}
      </List>
    )
  }

  const saveExistingRead = async () => {
    onOpenSession()
    setSaving(true)
    setSubscibeDialogOpen(false)

    let readSetupObject = {}
    let readSetupClone
    for (let i = 0; i < readSetup.length; i += 1) {
      for (let j = 0; j < readSetup[i].length; j += 1) {
        readSetupClone = [...readSetup]
        readSetupClone[i][j].lastDroppedItem.isFlipped = true
        readSetupObject = { ...readSetupObject, [i]: { ...readSetup[i] } }
      }
    }

    const readObj = {
      date: Date.now(),
      readType,
      readTitle,
      readTitleAr,
      readerName,
      readerBirthDate,
      readSubject,
      readQuestion,
      readSetup: readSetupObject,
      isCustomRead,
    }

    try {
      const reads = await getSavedReads(auth.user.uid)
      if (reads.length < vars.MAX_SAVED_READS) {
        await saveRead(auth.user.uid, readObj)
        setReadSaved(true)
        onSetNotification({
          message: language.languageVars.notifications.readSaveSuccess,
          type: 'success',
        })
      } else {
        onSetNotification({
          message: language.languageVars.notifications.readSaveLimit,
          type: 'warning',
        })
      }
      setSaving(false)
    } catch (err) {
      onSetNotification({
        message: language.languageVars.notifications.readSaveFail,
        type: 'error',
      })
    }
  }

  const saveReadHandler = () => {
    if (auth.subscriberStatus || isSubscriberSessionOpen) {
      saveExistingRead()
    } else {
      saveExistingRead()
      // setSubscibeDialogOpen(true)
    }
  }

  const newRead = () => {
    history.push('/reads/?lang=ar&&apptype=blog')
  }

  return (
    <>
      {saving && <LoadingBackdrop loadingText={language.languageVars.loadingMessages.savingRead} />}
      <PageTitle title={`${pageTitle()}: ${readTitleName || '...'}`} />
      {isLoadedRead && (
        <Suspense fallback="Loading...">
          <ReadInfo
            readDate={new Date(readDate).toLocaleString()}
            readerName={readerName}
            readerBirthDate={readerBirthDate}
            readSubject={readSubject}
            readQuestion={readQuestion}
          />
        </Suspense>
      )}
      {!isLoadedRead && !isCustomRead && numberOfFlippedCards !== maxSelectedCards ? (
        <Box mb={3}>
          <Typography className={`${classes.cardSelectInfo} ${language.direction === 'rtl' ? classes.arabicFont : ''}`} align="center" variant="body1" component="p">
            <b>
              {language.languageVars.messages.flipCards}
            </b>
          </Typography>
        </Box>
      ) : null}
      {setupReadCards()}
      <Element name="meaningContainer">
        {
          numberOfFlippedCards === maxSelectedCards || isLoadedRead || isCustomRead ? (
            setupReadMeaning()
          ) : null
        }
      </Element>
      <Box className={classes.adviceContainer}>
        <Typography className={`${classes.adviceTitle} ${language.direction === 'rtl' ? classes.arabicFont : ''}`} align="center" variant="body1" component="p">
          <b>
            {readAdvicetitle}
          </b>
        </Typography>
        {numberOfFlippedCards === maxSelectedCards || isLoadedRead || isCustomRead ? (
          setupReadAdvice()
        ) : (
          <Typography className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`} align="center" variant="body1" component="p">
            {readAdviceMessage}
          </Typography>
        )}
      </Box>
      {(readType === 'two_cards' || readType === 'one_card') && (
        <Typography className={`${classes.adviceTagline} ${language.direction === 'rtl' ? classes.arabicFont : ''}`} align="center" variant="body1" component="p">
          {language.languageVars.data.reader.answer.remember}
        </Typography>
      )}
      <Box mt={3} mb={3} className={classes.meaningTitle}>
        {(numberOfFlippedCards === maxSelectedCards || isCustomRead) && !blogRead
          ? (
            <Button className={language.direction === 'rtl' ? classes.arabicFont : ''} variant="contained" color="primary" disabled={readSaved} onClick={() => saveReadHandler()}>
              {language.languageVars.buttons.saveRead}
            </Button>
          ) : null}
        {blogRead && (
          <Button className={language.direction === 'rtl' ? classes.arabicFont : ''} variant="contained" color="primary" disabled={readSaved} onClick={() => newRead()}>
            {language.languageVars.buttons.newRead}
          </Button>
        )}
      </Box>
      <Suspense fallback="Loading...">
        <PlayStoreBanner />
      </Suspense>
      <Suspense fallback="Loading...">
        <DetailsDialog dialogOpen={dialogOpen} closeDialog={closeDialogHandler} cardInfo={cardInfo} />
      </Suspense>
      <Suspense fallback="Loading...">
        <SubscribeDialog dialogOpen={subscibeDialogOpen} closeDialog={closeSubscribeDialogHandler} paymentSuccess={saveExistingRead} />
      </Suspense>
    </>
  )
}

const mapStateToProps = state => ({
  maxSelectedCards: state.reader.maxSelectedCards,
  numberOfFlippedCards: state.reader.numberOfFlippedCards,
  createdRead: state.reader.createdRead,
  readSetup: state.reader.readSetup,
  readType: state.reader.readType,
  readTitle: state.reader.readTitle,
  readTitleAr: state.reader.readTitleAr,
  readerName: state.reader.readerName,
  readerBirthDate: state.reader.readerBirthDate,
  readSubject: state.reader.readSubject,
  readQuestion: state.reader.readQuestion,
  readDate: state.reader.readDate,
  isCustomRead: state.reader.isCustomRead,
  isLoadedRead: state.reader.isLoadedRead,
  isSubscriberSessionOpen: state.subscriberSession.isSubscriberSessionOpen,
})

const mapDispatchToProps = dispatch => ({
  onSetNotification: notification => dispatch(actions.setNotification(notification)),
  onFlipCard: index => dispatch(actions.flipCard(index)),
  onOpenSession: () => dispatch(actions.openSession()),
})

Reader.defaultProps = {
  maxSelectedCards: 0,
  numberOfFlippedCards: 0,
  readSetup: [],
  createdRead: [],
  readType: null,
  readTitle: null,
  readTitleAr: null,
  readerName: null,
  readerBirthDate: null,
  readSubject: null,
  readQuestion: null,
  isCustomRead: false,
  isLoadedRead: false,
  readDate: null,
  isSubscriberSessionOpen: false,
}

Reader.propTypes = {
  maxSelectedCards: PropTypes.number,
  numberOfFlippedCards: PropTypes.number,
  readSetup: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.object,
  ]))),
  readTitle: PropTypes.string,
  readTitleAr: PropTypes.string,
  readerName: PropTypes.string,
  readerBirthDate: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.object,
  ])),
  readSubject: PropTypes.string,
  readQuestion: PropTypes.string,
  createdRead: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.object,
  ]))),
  readType: PropTypes.string,
  onSetNotification: PropTypes.func.isRequired,
  onFlipCard: PropTypes.func.isRequired,
  isCustomRead: PropTypes.bool,
  isLoadedRead: PropTypes.bool,
  readDate: PropTypes.number,
  onOpenSession: PropTypes.func.isRequired,
  isSubscriberSessionOpen: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(Reader)
