import React, {
  useEffect, useState, useRef, lazy, Suspense,
} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import {
  Box,
} from '@material-ui/core'

import { useTheme } from '@material-ui/core/styles'
import { useLanguage } from '../../hooks/useLang'
import { useAuth } from '../../hooks/use-auth'

import LoadingBackdrop from '../../components/Loading/LoadingBackdrop'

import { shuffleArray } from '../../utilities/utils'

import { getUserById } from '../../API/users'

import { createFormElementObj, adjustFormValues, createFormValuesObj } from '../../utilities/form'

import * as actions from '../../store/actions'

import { spread } from './styles'

const PageTitle = lazy(() => import('../../layout/PageTitle'))
const ReadPref = lazy(() => import('../../components/Spread/ReadPref'))
const Messages = lazy(() => import('../../components/Spread/Messages'))
const Reader = lazy(() => import('../Reader/Reader'))

const Spread = ({
  onLoadCards,
  onLoadCustomReadSetup,
  onCreateRead,
  selectedCards,
  maxSelectedCards,
  loading,
  cards,
  cardsCount,
  readSetup,
  readTitle,
  readTitleAr,
  onAddCardToRead,
  onAddReadPref,
}) => {
  const { readRef } = useParams()
  const [isSpread, setIsSpread] = useState(false)
  const [isShuffeling, setIsShuffeling] = useState(false)
  const [shuffled, setShuffled] = useState(false)
  const [shuffeledCards, setShuffeledCards] = useState(null)
  const [readStart, setReadStart] = useState(false)
  const [reversedEnabled, setReversedEnabled] = useState(true)
  const [loadingSetup, setLoadingSetup] = useState(false)
  const [readPrefValid, setReadPrefValid] = useState(false)
  const [readPrefAdded, setReadPrefAdded] = useState(false)
  const language = useLanguage()
  const [readPref, setReadPref] = useState({
    readerName: createFormElementObj('input', language.languageVars.data.spread.form.readerName,
      { type: 'text', name: 'reader_name', placeholder: language.languageVars.data.spread.form.readerName }, '', null, { required: true }, false,
      {
        xs: 12,
        sm: null,
        md: null,
        lg: null,
        xl: null,
        fullWidth: true,
      }),
    readerBirthDate: createFormElementObj('date', language.languageVars.data.spread.form.readerBirthDate,
      { type: 'text', name: 'reader_birth_date', placeholder: language.languageVars.data.spread.form.readerBirthDate }, '', null, { required: false }, false,
      {
        xs: 12,
        sm: null,
        md: null,
        lg: null,
        xl: null,
        fullWidth: true,
      }),
    readSubject: createFormElementObj('select', language.languageVars.data.spread.form.readSubject,
      { name: 'read-subject' }, language.languageVars.data.spread.form.readSubjectList[0], [
        { value: language.languageVars.data.spread.form.readSubjectList[0], display: language.languageVars.data.spread.form.readSubjectList[0] },
        { value: language.languageVars.data.spread.form.readSubjectList[1], display: language.languageVars.data.spread.form.readSubjectList[1] },
        { value: language.languageVars.data.spread.form.readSubjectList[2], display: language.languageVars.data.spread.form.readSubjectList[2] },
      ], { required: true }, false, {
        xs: 12,
        sm: null,
        md: null,
        lg: null,
        xl: null,
        fullWidth: true,
      }),
    readQuestion: createFormElementObj('textarea', language.languageVars.data.spread.form.readQuestion,
      { type: 'text', name: 'read-question', placeholder: language.languageVars.data.spread.form.readQuestion }, '', null, { required: false }, false,
      {
        xs: 12,
        sm: null,
        md: null,
        lg: null,
        xl: null,
        fullWidth: true,
      }),
  })
  const numberOfSelectedCards = useRef()
  const currentReadRow = useRef(0)
  const currentReadCol = useRef(0)

  const auth = useAuth()
  const theme = useTheme()
  const classes = spread()
  const cardBackImage = theme.name === 'light' ? 'card-d' : 'card-l'
  const readTitleName = language.direction === 'rtl' ? readTitleAr : readTitle
  const shuffleSound = new Audio('/assets/sound/shuffle.mp3')
  shuffleSound.loop = true
  const [play, setPlay] = useState(false)
  const [pause, setPause] = useState(true)
  console.log(play);
  console.log(pause);
  const location = useLocation()
  const { apptype } = queryString.parse(location.search)
  const noRedPref = apptype && apptype === 'blog'

  numberOfSelectedCards.current = selectedCards.length

  useEffect(() => {
    let mounted = true

    if (mounted) {
      (async () => {
        if (!noRedPref) {
          const data = await getUserById(auth.user.uid)
          const adjustedForm = await adjustFormValues(readPref, data, null)
          setReadPref(prevForm => ({ ...prevForm, ...adjustedForm.adjustedForm }))
          setReadPrefValid(adjustedForm.formValid)
        } else {
          setReadPrefValid(true)
        }
        if (!cards) {
          await onLoadCards()
        } else {
          onLoadCustomReadSetup(readRef)
        }
      })()
    }

    return () => { mounted = false }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user.uid, cards, onLoadCards, onLoadCustomReadSetup, readRef])

  const playAudio = () => {
    setPlay(true)
    setPause(false)
    shuffleSound.play()
  }

  const pauseAudio = () => {
    setPlay(false)
    setPause(true)
    shuffleSound.pause()
  }

  const spreadDeckHandler = () => {
    setIsSpread(true)
  }

  const shuffleDeckHandler = () => {
    setIsShuffeling(true)
    playAudio()
    setShuffeledCards(shuffleArray([...cards]))
    setTimeout(() => {
      setIsShuffeling(false)
      pauseAudio()
    }, 3000)
    if (!shuffled) {
      setShuffled(true)
    }
  }

  const selectCardHandler = card => {
    if (!shuffled) {
      shuffleDeckHandler()
      return
    }
    if (!isSpread) {
      spreadDeckHandler()
      return
    }

    const isReversed = reversedEnabled ? Math.random() <= 0.5 : false
    const selectedCard = card
    selectedCard.isFlipped = false
    selectedCard.isReversed = isReversed
    selectedCard.index = numberOfSelectedCards.current

    onAddCardToRead(currentReadRow.current, currentReadCol.current, card, isReversed)

    if (currentReadCol.current < readSetup[currentReadRow.current].length) {
      currentReadCol.current += 1
    }
    if (readSetup[currentReadRow.current].length === currentReadCol.current) {
      currentReadRow.current += 1
      currentReadCol.current = 0
    }

    numberOfSelectedCards.current += 1

    if (numberOfSelectedCards.current === maxSelectedCards) {
      setIsSpread(false)
      setLoadingSetup(true)
      setTimeout(() => {
        onCreateRead(readRef, false, false)
        setReadStart(true)
        setLoadingSetup(false)
      }, 2000)
    }
  }

  const isCardSelected = card => {
    let selected = false

    if (selectedCards.length > 0) {
      selectedCards.map(selectedCard => {
        if (selectedCard === card.name) {
          selected = true
        }
        return selected
      })
    }
    return selected
  }

  const generateCards = () => {
    let spreadCards
    const deck = shuffeledCards || cards
    const step = 360 / cardsCount
    const delay = 2 / cardsCount
    let angle = 0
    let seconds = 0

    if (cards) {
      spreadCards = deck.map((card, i) => {
        angle += step
        seconds += delay

        return (
          <div
            key={card.cardId}
            className={`card ${isShuffeling && 'shuffeling'} ${isCardSelected(card) && 'selected'}`}
            style={{
              transform: isSpread && `rotate(${angle}deg)`,
              animationDelay: isShuffeling && `${seconds}s`,
              zIndex: i,
              backgroundImage: `url(/assets/images/${cardBackImage}.png)`,
            }}
            aria-hidden="true"
            onClick={() => selectCardHandler(card)}
          >
            <span />
          </div>
        )
      })
    }

    return spreadCards
  }

  const changeReversedHandler = () => {
    setReversedEnabled(!reversedEnabled)
  }

  const updateReadPrefHandler = async e => {
    e.preventDefault()
    const readPrefDetails = createFormValuesObj(readPref)
    try {
      onAddReadPref(readPrefDetails)
      setReadPrefAdded(true)
    } catch (err) {
      setReadPrefAdded(false)
    }
  }

  const inputChangeHandler = (e, key) => {
    let changeEvent

    if (Array.isArray(e)) {
      changeEvent = e.join()
    } else if (Number.isInteger(e)) {
      changeEvent = String(e)
    } else {
      changeEvent = e
    }
    const adjustedForm = adjustFormValues(readPref, changeEvent, key)
    setReadPref(adjustedForm.adjustedForm)
    setReadPrefValid(adjustedForm.formValid)
  }

  if (readStart) { return <Suspense fallback="Loading..."><Reader /></Suspense> }

  return (
    <>
      {!cards || loading ? <LoadingBackdrop />
        : (
          <>
            <Suspense fallback="Loading...">
              <PageTitle title={`${language.languageVars.read}: ${readTitleName || '...'}`} />
            </Suspense>
            { readPrefAdded || noRedPref ? (
              <>
                <Suspense fallback="Loading...">
                  <Messages
                    isShuffeling={isShuffeling}
                    shuffled={shuffled}
                    isSpread={isSpread}
                    maxSelectedCards={maxSelectedCards}
                    selectedCardsLength={selectedCards.length}
                  />
                </Suspense>
                <Box className={classes.cardsContainer}>
                  {loadingSetup && <LoadingBackdrop />}
                  <div className={`${classes.pactCount} ${isSpread && classes.pactSpread}`}>
                    {generateCards()}
                  </div>
                </Box>
              </>
            ) : (
              <Suspense fallback="Loading...">
                <ReadPref
                  pref={readPref}
                  valid={readPrefValid}
                  loading={loading}
                  onChange={inputChangeHandler}
                  checkBoxChecked={reversedEnabled}
                  checkboxChanged={changeReversedHandler}
                  onSubmit={updateReadPrefHandler}
                />
              </Suspense>
            )}
          </>
        )}
    </>
  )
}

const mapStateToProps = state => ({
  loading: state.cards.loading,
  cards: state.cards.cards,
  cardsCount: state.cards.cardsCount,
  readSetup: state.reader.readSetup,
  readTitle: state.reader.readTitle,
  readTitleAr: state.reader.readTitleAr,
  selectedCards: state.reader.selectedCards,
  maxSelectedCards: state.reader.maxSelectedCards,
})

const mapDispatchToProps = dispatch => ({
  onLoadCards: () => dispatch(actions.loadCards()),
  onLoadCustomReadSetup: readRef => dispatch(actions.loadCustomReadSetup(readRef)),
  onAddCardToRead: (rowIndex, colIndex, card, cardState) => dispatch(actions.addCard(rowIndex, colIndex, card, cardState)),
  onCreateRead: (readType, isCustomRead, isLoadedRead) => (dispatch(actions.createRead(readType, isCustomRead, isLoadedRead))),
  onAddReadPref: (readerName, readerBirthDate, readSubject, readQuestion) => (dispatch(actions.addReadPref(readerName, readerBirthDate, readSubject, readQuestion))),
})

Spread.defaultProps = {
  loading: false,
  cards: null,
  cardsCount: 0,
  readSetup: [],
  readTitle: null,
  readTitleAr: null,
  selectedCards: [],
  maxSelectedCards: null,
}

Spread.propTypes = {
  loading: PropTypes.bool,
  cards: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.object,
  ]))),
  cardsCount: PropTypes.number,
  onLoadCards: PropTypes.func.isRequired,
  onLoadCustomReadSetup: PropTypes.func.isRequired,
  readSetup: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.object,
  ]))),
  readTitle: PropTypes.string,
  readTitleAr: PropTypes.string,
  onAddCardToRead: PropTypes.func.isRequired,
  onCreateRead: PropTypes.func.isRequired,
  selectedCards: PropTypes.arrayOf(PropTypes.string),
  maxSelectedCards: PropTypes.number,
  onAddReadPref: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Spread)
