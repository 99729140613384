import React, {
  useState, useEffect, lazy, Suspense,
} from 'react'
import { useHistory } from 'react-router-dom'

import Box from '@material-ui/core/Box'

import { getAllUsers, setUserClaims } from '../../API/users'
import { useAuth } from '../../hooks/use-auth'
import { getFirebaseFunctions } from '../../API/firebase'

import * as vars from '../../utilities/appVars'

const UsersList = lazy(() => import('../../components/Users/UsersList'))

const Users = () => {
  const [users, setUsers] = useState(null)
  const [processingRequest, setProcessingRequest] = useState(false)
  const auth = useAuth()
  const history = useHistory()

  useEffect(() => {
    let mounted = true
    let { user } = auth
    const { superAdminStatus } = auth

    if (mounted) {
      (async () => {
        if (user) {
          if (superAdminStatus) {
            const allUsers = await getAllUsers(auth.user.email);
            setUsers(allUsers)
          } else {
            history.push(vars.LOGIN_REDIRECT)
          }
        }
      })()
    }

    return () => {
      mounted = false
      user = null
    }
  }, [auth, history])

  const makeAdminHandler = async email => {
    setProcessingRequest(true)
    const dbFunctions = await getFirebaseFunctions()
    const addAdminRole = dbFunctions.httpsCallable('addAdminRole')
    try {
      const res = await addAdminRole({ email })
      setProcessingRequest(false)
      return res.data.message
    } catch (err) {
      setProcessingRequest(false)
      throw new Error(err)
    }
  }

  const disableAdminHandler = async email => {
    setProcessingRequest(true)
    const dbFunctions = await getFirebaseFunctions()
    const disableAdminRole = dbFunctions.httpsCallable('disableAdminRole')
    try {
      const res = await disableAdminRole({ email })
      setProcessingRequest(false)
      return res.data.message
    } catch (err) {
      setProcessingRequest(false)
      throw new Error(err)
    }
  }

  const changeUserClaim = (userId, claim) => {
    setUserClaims(userId, claim)
  }

  const makeSubscriberHandler = async email => {
    setProcessingRequest(true)
    const dbFunctions = await getFirebaseFunctions()
    const addSubscriberRole = dbFunctions.httpsCallable('addSubscriberRole')
    try {
      const res = await addSubscriberRole({ email })
      setProcessingRequest(false)
      return res.data.message
    } catch (err) {
      setProcessingRequest(false)
      throw new Error(err)
    }
  }

  const disableSubscriberHandler = async email => {
    setProcessingRequest(true)
    const dbFunctions = await getFirebaseFunctions()
    const disableSubscriberRole = dbFunctions.httpsCallable('disableSubscriberRole')
    try {
      const res = await disableSubscriberRole({ email })
      setProcessingRequest(false)
      return res.data.message
    } catch (err) {
      setProcessingRequest(false)
      throw new Error(err)
    }
  }

  return (
    <Box mx="auto" mt={5} mb={5}>
      <Suspense fallback="Loading...">
        <UsersList
          users={users}
          makeAdmin={makeAdminHandler}
          disableAdmin={disableAdminHandler}
          changeUserClaim={changeUserClaim}
          makeSubscriber={makeSubscriberHandler}
          disableSubscriber={disableSubscriberHandler}
          processingRequest={processingRequest}
        />
      </Suspense>
    </Box>
  )
}

export default Users
