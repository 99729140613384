import React from 'react'

import { Link } from 'react-router-dom'

import {
  Box, Grid, Typography, List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core'

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

import PageTitle from '../../layout/PageTitle'

import { useLanguage } from '../../hooks/useLang'

import { philosophyStyles } from './styles'

const Privacy = () => {
  const classes = philosophyStyles()
  const language = useLanguage()

  return (
    <Box className={classes.landingContainer} dir={language.direction}>
      <Box className={classes.hero} mt={6}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PageTitle title={language.languageVars.titles.privacy.title} />
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p1}
            </Typography>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p2}
            </Typography>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p3}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box mt={4}>
              <Typography component="h4" variant="h4" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
                {language.languageVars.data.privacy.t1}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p4}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box mt={4}>
              <Typography component="h4" variant="h4" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
                {language.languageVars.data.privacy.t2}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p5}
            </Typography>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p6}
            </Typography>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p7}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box mt={4}>
              <Typography component="h4" variant="h4" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
                {language.languageVars.data.privacy.t3}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p8}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon />
                </ListItemIcon>
                <ListItemText
                  primary={language.languageVars.data.privacy.l1}
                  style={language.direction === 'rtl' ? { textAlign: 'right' } : { textAlign: 'left' }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon />
                </ListItemIcon>
                <ListItemText
                  primary={language.languageVars.data.privacy.l2}
                  style={language.direction === 'rtl' ? { textAlign: 'right' } : { textAlign: 'left' }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon />
                </ListItemIcon>
                <ListItemText
                  primary={language.languageVars.data.privacy.l3}
                  style={language.direction === 'rtl' ? { textAlign: 'right' } : { textAlign: 'left' }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon />
                </ListItemIcon>
                <ListItemText
                  primary={language.languageVars.data.privacy.l4}
                  style={language.direction === 'rtl' ? { textAlign: 'right' } : { textAlign: 'left' }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon />
                </ListItemIcon>
                <ListItemText
                  primary={language.languageVars.data.privacy.l5}
                  style={language.direction === 'rtl' ? { textAlign: 'right' } : { textAlign: 'left' }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon />
                </ListItemIcon>
                <ListItemText
                  primary={language.languageVars.data.privacy.l6}
                  style={language.direction === 'rtl' ? { textAlign: 'right' } : { textAlign: 'left' }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon />
                </ListItemIcon>
                <ListItemText
                  primary={language.languageVars.data.privacy.l7}
                  style={language.direction === 'rtl' ? { textAlign: 'right' } : { textAlign: 'left' }}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12}>
            <Box mt={4}>
              <Typography component="h4" variant="h4" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
                {language.languageVars.data.privacy.t4}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p9}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box mt={4}>
              <Typography component="h4" variant="h4" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
                {language.languageVars.data.privacy.t5}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p10}
            </Typography>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p11.text}
              <Link to="https://www.cookieconsent.com/what-are-cookies/" target="_blank">{language.languageVars.data.privacy.p11.link}</Link>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box mt={4}>
              <Typography component="h4" variant="h4" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
                {language.languageVars.data.privacy.t6}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p12.text}
              <Link to="https://policies.google.com/technologies/ads" target="_blank">{language.languageVars.data.privacy.p12.link}</Link>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box mt={4}>
              <Typography component="h4" variant="h4" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
                {language.languageVars.data.privacy.t7}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p13}
            </Typography>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p14}
            </Typography>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              <Link to="https://policies.google.com/technologies/ads" target="_blank">{language.languageVars.data.privacy.p15.link}</Link>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box mt={4}>
              <Typography component="h4" variant="h4" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
                {language.languageVars.data.privacy.t8}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p16}
            </Typography>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p17}
            </Typography>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p18}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box mt={4}>
              <Typography component="h4" variant="h4" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
                {language.languageVars.data.privacy.t9}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p19}
            </Typography>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p20}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box mt={4}>
              <Typography component="h4" variant="h4" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
                {language.languageVars.data.privacy.t10}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p21}
            </Typography>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p22}
            </Typography>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p23}
            </Typography>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p24}
            </Typography>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p25}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box mt={4}>
              <Typography component="h4" variant="h4" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
                {language.languageVars.data.privacy.t11}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p26}
            </Typography>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p27}
            </Typography>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p28}
            </Typography>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p29}
            </Typography>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p30}
            </Typography>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p31}
            </Typography>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p32}
            </Typography>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p33}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box mt={4}>
              <Typography component="h4" variant="h4" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
                {language.languageVars.data.privacy.t12}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p34}
            </Typography>
            <Typography component="p" variant="body1" className={`${language.direction === 'rtl' ? classes.arabicFont : ''}`}>
              {language.languageVars.data.privacy.p35}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default Privacy
