import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

export const useDarkMode = () => {
  const [theme, setTheme] = useState('light')
  const location = useLocation()
  const { apptype } = queryString.parse(location.search)
  const noStorage = apptype && apptype === 'blog'

  const toggleTheme = () => {
    if (theme === 'light') {
      window.localStorage.setItem('theme', 'dark')
      setTheme('dark')
    } else {
      window.localStorage.setItem('theme', 'light')
      setTheme('light')
    }
  };

  useEffect(() => {
    if (noStorage) {
      setTheme('light')
    } else {
      const localTheme = window.localStorage.getItem('theme')
      if (localTheme) {
        setTheme(localTheme)
      }
    }
  }, [noStorage]);

  return [theme, toggleTheme]
}
