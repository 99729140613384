import React, { lazy, Suspense } from 'react'

import { useHistory } from 'react-router-dom'

import {
  Box, Divider,
} from '@material-ui/core'

import { landingStyles } from './styles'

const Hero = lazy(() => import('../../components/Landing/HeroOne'))
const CardsBanner = lazy(() => import('../../components/Landing/CardsBanner'))
const BlogPosts = lazy(() => import('../../components/Landing/BlogPosts'))
const CustomReadsBanner = lazy(() => import('../../components/Landing/CustomReadsBanner'))

const Landing = () => {
  const classes = landingStyles()
  const history = useHistory()

  const normalReadHandler = () => {
    history.push('/reads')
  }

  const customReadHandler = () => {
    history.push('/customRead')
  }

  const allCardsHandler = () => {
    history.push('/cards')
  }

  return (
    <Box className={classes.landingContainer}>
      <Suspense fallback="Loading...">
        <Hero onNormalRead={normalReadHandler} onCustomRead={customReadHandler} />
      </Suspense>
      <Suspense fallback="Loading...">
        <CardsBanner onAllCards={allCardsHandler} />
      </Suspense>
      <Suspense fallback="Loading...">
        <BlogPosts />
      </Suspense>
      <Divider />
      <Suspense fallback="Loading...">
        <CustomReadsBanner onCustomRead={customReadHandler} />
      </Suspense>
    </Box>
  )
}

export default Landing
