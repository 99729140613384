export const navLinks = [
  { linkfor: 'cardsList', path: '/cards' },
  { linkfor: 'readsList', path: '/reads' },
  { linkfor: 'customRead', path: '/customRead' },
]
export const footerLinks = [
  { linkfor: 'philosophy', path: '/philosophy' },
  { linkfor: 'history', path: '/history' },
  { linkfor: 'learn', path: '/learn' },
  { linkfor: 'privacy', path: '/privacy' },
  { linkfor: 'contact', path: '/contact' },
]
export const userMenu = [
  { linkfor: 'myReads', path: '/myReads' },
  { linkfor: 'logout', path: '/logout' },
  { linkfor: 'users', path: '/users' },
]
export const AUTH_PAGE = '/auth'
export const LOGIN_REDIRECT = '/cards'
export const CONTACT_EMAIL = 'info@eltarot.app'
export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: 'tarot-331d9.firebaseapp.com',
  databaseURL: 'https://tarot-331d9.firebaseio.com',
  projectId: 'tarot-331d9',
  storageBucket: 'tarot-331d9.appspot.com',
  appId: '1:69353539593:web:16a214d9f4b1ebb00710d8',
  measurementId: 'G-XCLVKQ5Z46',
}
export const CARDS_IMAGES_DIRECTORY = '/assets/images/cards'
export const MAX_SAVED_READS = 10
export const STRIPE_PIBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PIBLISHABLE
export const STRIPE_SECRET_KEY = process.env.REACT_APP_STRIPE_SECRET
export const SUITS = [
  { name: 'major' },
  { name: 'cups' },
  { name: 'pentacles' },
  { name: 'wands' },
  { name: 'swords' },
]
