import * as actionTypes from './actionTypes'
import {
  getSavedReads, removeRead, setUserPref, getUserById,
} from '../../API/users'

export const getMyReadsRequest = () => ({
  type: actionTypes.GET_MY_READS_REQUEST,
})

export const getMyReadsSuccess = reads => ({
  type: actionTypes.GET_MY_READS_SUCCESS,
  reads,
  count: reads.length,
})

export const getMyReadsFailure = error => ({
  type: actionTypes.GET_MY_READS_FAILURE,
  error,
})

export const loadRead = loadedRead => ({
  type: actionTypes.LOAD_READ,
  loadedRead,
})

export const getUserPrefRequest = () => ({
  type: actionTypes.GET_USER_PREF_REQUEST,
})

export const getUserPrefSuccess = (userName, userBirthDate) => ({
  type: actionTypes.GET_USER_PREF_SUCCESS,
  userName,
  userBirthDate,
})

export const getUserPrefFailure = error => ({
  type: actionTypes.GET_USER_PREF_FAILURE,
  error,
})

export const loadMyReads = userId => async dispatch => {
  dispatch(getMyReadsRequest())
  try {
    const reads = await getSavedReads(userId)
    dispatch(getMyReadsSuccess(reads))
  } catch (err) {
    dispatch(getMyReadsFailure(err))
  }
}

export const removeOneRead = (currentReads, userId, readObj) => async dispatch => {
  dispatch(getMyReadsRequest())
  try {
    await removeRead(userId, readObj)
    const reads = currentReads.filter(read => read.date !== readObj.date)
    dispatch(getMyReadsSuccess(reads))
  } catch (err) {
    dispatch(getMyReadsFailure(err))
  }
}

export const loadOneRead = loadedRead => dispatch => {
  dispatch(loadRead(loadedRead))
}

export const loadUserPref = userId => async dispatch => {
  dispatch(getUserPrefRequest())
  try {
    const user = await getUserById(userId)
    dispatch(getUserPrefSuccess(user.readerName, user.readerBirthDate))
  } catch (err) {
    dispatch(getUserPrefFailure(err))
  }
}

export const changeUserPref = (userId, readerName, readerBirthDate) => async dispatch => {
  dispatch(getUserPrefRequest())
  try {
    await setUserPref(userId, readerName, readerBirthDate)
    dispatch(getUserPrefSuccess(readerName, readerBirthDate))
  } catch (err) {
    dispatch(getUserPrefFailure(err))
  }
}
