import React from 'react'

import PropTypes from 'prop-types'

// import ImageUploader from 'react-images-upload'

import {
  TextField, InputLabel, FormControl, Select, FormHelperText, Grid, TextareaAutosize, Typography, Slider, FormGroup, FormControlLabel, Checkbox,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import ChipInput from 'material-ui-chip-input'

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsAdapter from '@date-io/date-fns'
import { useLanguage } from '../../hooks/useLang'

// import * as vars from '../../utilities/appVars'

import { formStyles } from './styles'

const FormElement = ({
  valid,
  shouldValidate,
  touched,
  errorMessage,
  value,
  changed,
  elementSetup,
  label,
  elementOptions,
  elementType,
  grid,
  disabled,
}) => {
  const classes = formStyles()
  const language = useLanguage()
  const createSelectOptions = opts => opts.map((opt, i) => <option key={opt + i} value={opt.value} className={language.direction === 'rtl' ? classes.arabicFont : ''}>{opt.display}</option>)

  let inputEl = null
  let chipsArray = []
  const inputClasses = !valid && shouldValidate && touched ? `${classes.inputEl} ${classes.Invalid}` : classes.inputEl
  const rtlClass = language.direction === 'rtl' ? classes.rtlInput : ''

  const dateFns = new DateFnsAdapter()

  switch (elementType) {
    case ('textarea'):
      inputEl = (
        <TextField
          className={`${inputClasses} ${rtlClass}`}
          label={label}
          multiline
          rowsMax={4}
          value={value}
          onChange={changed}
          error={!valid}
          helperText={errorMessage}
          disabled={disabled}
          fullWidth={grid.fullWidth}
          {...elementSetup}
        />
      )
      break
    case ('textarea-auto-size'):
      inputEl = (
        <TextareaAutosize
          className={`${inputClasses} ${rtlClass}`}
          label={label}
          multiline
          rowsMin={4}
          value={value}
          onChange={changed}
          error={!valid}
          helperText={errorMessage}
          disabled={disabled}
          fullWidth={grid.fullWidth}
          {...elementSetup}
        />
      )
      break
    case ('checkbox'):
      inputEl = (
        <FormGroup row>
          <FormControlLabel
            control={(
              <Checkbox
                checked={value}
                onChange={changed}
                color="primary"
                {...elementSetup}
              />
            )}
            label={label}
            classNaclearableme={`${classes.formLabel} ${language.direction === 'rtl' ? classes.arabicFont : ''}`}
            dir={language.direction}
          />
        </FormGroup>
      )
      break
    case ('select'):
      inputEl = (
        <>
          <InputLabel htmlFor={elementSetup.name} className={`${inputClasses} ${rtlClass}`}>{label}</InputLabel>
          <Select
            native
            error={!valid}
            value={value}
            onChange={changed}
            fullWidth={grid.fullWidth}
            disabled={disabled}
            className={`${rtlClass} ${language.direction === 'rtl' ? classes.arabicFont : ''}`}
            MenuProps={{ classes: { paper: classes.select, list: classes.select } }}
            inputProps={{
              name: elementSetup.name,
              id: elementSetup.name,
            }}
          >
            <option aria-label="None" value="" />
            {createSelectOptions(elementOptions)}
          </Select>
          <FormHelperText className={`Mui-error ${classes.rtlLabel}`}>{errorMessage}</FormHelperText>
        </>
      )
      break
    case ('autoComplete'):
      inputEl = (
        <Autocomplete
          multiple
          id="tags-standard"
          options={elementOptions}
          getOptionLabel={tag => tag.title}
          defaultValue={value}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label="Multiple values"
              placeholder="Favorites"
              fullWidth={grid.fullWidth}
              disabled={disabled}
            />
          )}
        />
      )
      break
    case ('tags'):
      chipsArray = value
      if (typeof value === 'string') {
        chipsArray = value.split(',')
      }
      inputEl = (
        <>
          <ChipInput defaultValue={chipsArray} label={label} onChange={changed} error={!valid} />
          <FormHelperText>{errorMessage}</FormHelperText>
        </>
      )
      break
    // case ('imageUpload'):
    //   inputEl = (
    //     <>
    //       <ImageUploader
    //         {...elementSetup}
    //         withIcon
    //         withPreview
    //         singleImage
    //         onChange={changed}
    //         imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
    //         maxFileSize={5242880}
    //       />
    //       {!touched && <img src={`${vars.CARDS_IMAGES_DIRECTORY}/${value}`} alt={value} width="150" />}
    //     </>
    //   )
    //   break
    case ('slider'):
      inputEl = (
        <>
          <Typography id="discrete-slider" gutterBottom>
            {label}
          </Typography>
          <Slider
            value={parseInt(value, 10) || 0}
            aria-valuetext={value.toString()}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="on"
            step={10}
            onChange={(e, sliderValue) => changed(sliderValue)}
            marks
            min={0}
            max={100}
            classes={{
              root: classes.sliderRoot,
              track: classes.sliderTrack,
              rail: classes.sliderRail,
              thumb: classes.sliderThumb,
              mark: classes.sliderMark,
              valueLabel: classes.valueLabel,
              markLabel: classes.markLabel,
            }}
          />
        </>
      )
      break
    case ('date'):
      inputEl = (
        <>
          <MuiPickersUtilsProvider utils={DateFnsAdapter}>
            <DatePicker
              disableFuture
              label={label}
              className={`${rtlClass}`}
              value={dateFns.date(value)}
              onChange={changed}
              openTo="year"
              format="dd - MM - yyyy"
              views={['year', 'month', 'date']}
              clearable
            />
          </MuiPickersUtilsProvider>
        </>
      )
      break
    default:
      inputEl = (
        <TextField
          dir={language.direction}
          className={`${inputClasses} ${rtlClass}`}
          label={label}
          value={value}
          onChange={changed}
          error={!valid}
          helperText={errorMessage}
          fullWidth={grid.fullWidth}
          disabled={disabled}
          {...elementSetup}
        />
      )
      break
  }

  return (
    <Grid item xs={grid.xs} sm={grid.sm} md={grid.md} lg={grid.lg} xl={grid.xl}>
      <FormControl fullWidth={grid.fullWidth} dir={language.direction}>
        {inputEl}
      </FormControl>
    </Grid>
  )
}

FormElement.defaultProps = {
  valid: false,
  shouldValidate: null,
  touched: false,
  errorMessage: null,
  value: null,
  elementSetup: null,
  label: null,
  elementOptions: null,
  elementType: null,
  grid: null,
  disabled: false,
}

FormElement.propTypes = {
  valid: PropTypes.bool,
  shouldValidate: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.object,
  ])),
  touched: PropTypes.bool,
  errorMessage: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.objectOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ])),
  ]),
  changed: PropTypes.func.isRequired,
  elementSetup: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ])),
  label: PropTypes.string,
  elementOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.object,
  ]))),
  elementType: PropTypes.string,
  grid: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ])),
  disabled: PropTypes.bool,
}

export default FormElement
