import { makeStyles } from '@material-ui/core/styles'

export const formStyles = makeStyles(theme => ({
  input: {
    width: '100%',
    boxSizing: 'border-box',
    padding: theme.spacing(1),
  },
  label: {
    fontWeight: 'bold',
    marginBottom: '8px',
    display: 'block',
  },
  inputEl: {
    outline: 'none',
    display: 'block',
    width: '100%',
    boxSizing: 'border-box',
    '&.Invalid': {
      borderColor: '#dd0000',
    },
    '&:focus': {
      outline: 'none',
      backgroundColor: '#ccc',
    },
  },
  select: {
    background: '#dd0000',
    '& ul': {
      backgroundColor: '#dd0000',
    },
    '& li': {
      fontSize: 12,
    },
  },
  rtlInput: {
    textAlign: 'right',
    paddingRight: 0,
    '& .MuiInputLabel-shrink': {
      transformOrigin: 'top right',
      fontFamily: theme.fonts.arabic,
    },
    '&.MuiInputLabel-shrink': {
      transformOrigin: 'top right',
      fontFamily: 'Cairo',
    },
    '& .MuiInputLabel-formControl': {
      left: 'auto',
      right: 0,
      fontFamily: 'Cairo',
    },
    '& .MuiFormHelperText-root': {
      textAlign: 'right',
      fontFamily: theme.fonts.arabic,
    },
    '& .MuiSelect-select': {
      paddingRight: 0,
    },
    '& .MuiSelect-icon': {
      right: 'auto',
      left: 0,
    },
  },
  rtlLabel: {
    textAlign: 'right',
    fontFamily: 'Cairo',
  },
  formLabel: {
    marginRight: 0,
    '& .MuiTypography-body1': {
      fontSize: '0.8rem',
    },
    '& svg': {
      stroke: theme.palette.background.reverse,
    },
  },
  sliderRoot: {
    backgroundColor: theme.palette.background.reverse,
  },
  sliderTrack: {
    backgroundColor: theme.palette.background.highlight,
  },
  sliderRail: {
    backgroundColor: theme.palette.background.default,
  },
  sliderThumb: {
    backgroundColor: theme.palette.background.highlight,
  },
  sliderMark: {
    backgroundColor: theme.palette.background.highlight,
  },
  valueLabel: {
    '& .PrivateValueLabel-circle-179': {
      backgroundColor: theme.palette.background.highlight,
      '& .PrivateValueLabel-label-180': {
        color: theme.palette.background.reverse,
      },
    },
  },
  markLabel: {
    backgroundColor: theme.palette.background.highlight,
  },
  arabicFont: {
    fontFamily: theme.fonts.arabic,
  },
}))
