export const ar = {
  language: 'English',
  languageShort: 'EN',
  appName: 'التاروت',
  login: 'تسجيل الدخول',
  cards: 'البطاقات',
  oneCard: 'كارت واحد',
  read: 'قراءة',
  customRead: 'قراءة مخصصة',
  savedRead: 'القراءات المحفوظة',
  reversed: 'معكوس',
  titles: {
    privacy: {
      title: 'سياسة الخصوصية',
    },
    landing: {
      hero: 'معاني البطاقات<br />قراءات عادية<br />قراءات مخصصة',
      learn: 'تعلم التاروت عن طريق تكوين قراءتك',
      cardsCarousel: 'تعرف على معاني البطاقات',
      heroOne: 'تعلم التاروت و إكتشف المزيد عن ذاتك',
      cardsBanner: 'تعرف على معاني بطاقات التاروت',
      blogPosts: 'من مدونة التاروت',
    },
    auth: {
      title: 'تسجيل الدخول',
      titleInfo: 'اختر وسيلة الدخول',
    },
    reads: {
      title: {
        read: 'اختر نوع القراءة',
        customRead: 'اختر نوع القراءة المخصصة',
      },
      titleInfo: 'اختر نوع القراءة',
    },
    myAccount: {
      myPref: {
        title: 'معلوماتي',
      },
      myReads: {
        title: 'القراءات المحفوظة',
        titleInfoWithReads: 'اضغط على قراءة للتحميل',
        titleInfoWithoutReads: 'لا يوجد قراءات محفوظة',
      },
    },
    reader: {
      advice: {
        title: {
          oneCard: 'الإجابة',
          twoCards: 'الإختيار',
          general: 'نصائح',
        },
      },
    },
    history: {
      title: 'تاريخ موجز للتاروت',
      titleInfo: 'للترفيه و ليس للعرافة',
    },
    learn: {
      title: 'التاروت بإمكانه المساعدة',
      titleInfo: 'يتطلب الأمر الكثير من التأمل والإدراك العميق للكشف عن الحقائق الأعمق في بطاقات التاروت الخاصة بك.'
      + 'عليك التوفيق بين المعاني والتفسيرات وجعلها ذات صلة بك من خلال التعمق في أفكارك وخبراتك.'
      + 'تحتاج إلى ربط التفسيرات بماضيك ، وحياتك اليومية ، وذاتك ، وفهم كيفية تطبيقها.',
    },
    philosophy: {
      title: 'فلسفتنا للتاروت',
      titleInfo: 'اصنع عالمك السحري',
    },
    support: {
      subscribed: {
        title: 'شكرا',
        titleInfo: 'دعمك يعني الكثير لنا ولمجتمع التاروت. سنبذل قصارى جهدنا للحفاظ على خدمة جيدة وإضافة المزيد من الميزات إلى ElTarot.',
      },
      notSubscribed: {
        title: 'ادعمنا',
        titleInfo: 'سيساعدنا دعمك على إضافة المزيد من الميزات إلى ElTarot. وسنتوقف عن عرض الإعلانات في المقابل',
      },
    },
    customRead: 'قراءة مخصصة',
    editCard: 'تعديل',
    paymentFormTitle: 'بيانات البطاقة',
    playStore: 'نسخة أندرويد',
  },
  data: {
    meta: {
      title: 'معاني بطاقات التاروت - قراءات التاروت - تعلم التاروت',
      description: 'التاروت هو أداة قوية للمساعدة في اكتشاف الذات و إدراك الأفكار. التاروت ليس أداة للسحر أو معرفة المستقبل ، التاروت يساعدنا ببساطة في إطلاق أفكارنا وتنظيمها بطرق منطقية في سبيل عيش أفضل حياة ممكنة.',
    },
    landing: {
      heroText: 'التاروت هو أداة قوية للمساعدة في اكتشاف الذات و إدراك الأفكار. التاروت ليس أداة للسحر أو معرفة المستقبل ، التاروت يساعدنا ببساطة في إطلاق أفكارنا وتنظيمها بطرق منطقية في سبيل عيش أفضل حياة ممكنة.',
      learnText: 'قم بإنشاء قراءات بأي مجموعة كروت تفكر بها للتعرف على التخطيطات والمعاني المختلفة أو استخدمها لترجمة القراءات التي تم إنشاؤها بواسطة مجموعة البطاقات الخاصة بك.',
      cardsBanner: 'يمكنك البحث عن معنى اي من بطاقات التاروت و التعرف على المجموعات المختلفة للبطاقات',
      blogPosts: [
        {
          title: 'التاروت و بطاقات السر الأعظم: رحلة الأحمق',
          description: 'ترمز كل بطاقة من البطاقات الـ 22 لجانب مختلف من العقل وتسعى معه البطاقات في رحلة من مرحلة التشتت إلى مرحلة الكمال.',
          image: 'fools-journey',
          link: 'https://www.eltarot.blog/post/%D8%A7%D9%84%D8%AA%D8%A7%D8%B1%D9%88%D8%AA-%D9%88-%D8%A8%D8%B7%D8%A7%D9%82%D8%A7%D8%AA-%D8%A7%D9%84%D8%B3%D8%B1'
          + '-%D8%A7%D9%84%D8%A3%D8%B9%D8%B8%D9%85-%D8%B1%D8%AD%D9%84%D8%A9-%D8%A7%D9%84%D8%A3%D8%AD%D9%85%D9%82',
        },
        {
          title: 'التاروت ودائرة الأبراج',
          description: 'تتوافق مجموعات التاروت الأربعة مع عناصر الحياة الأربعة في علم الأبراج',
          image: 'zodiac',
          link: 'https://www.eltarot.blog/post/%D8%A7%D9%84%D8%AA%D8%A7%D8%B1%D9%88%D8%AA-%D9%88%D8%AF%D8%A7%D8%A6%D8%B1%D8%A9-%D8%A7%D9%84%D8%A3%D8%A8%D8%B1%D8%A7%D8%AC',
        },
        {
          title: 'التاروت - بطاقات السر الأصغر : مجموعة الكؤوس',
          description: 'تمثل المجموعة الصداقة وحب البشر بشكل عام.',
          image: 'cups',
          link: 'https://www.eltarot.blog/post/%D8%A7%D9%84%D8%AA%D8%A7%D8%B1%D9%88%D8%AA-%D8%A8%D8%B7%D8%A7%D9%82%D8%A7%D8%AA-'
          + '%D8%A7%D9%84%D8%B3%D8%B1-%D8%A7%D9%84%D8%A3%D8%B5%D8%BA%D8%B1-%D9%85%D8%AC%D9%85%D9%88%D8%B9%D8%A9-%D8%A7%D9%84%D9%83%D8%A4%D9%88%D8%B3',
        },
        {
          title: 'التاروت -بطاقات السر الأصغر: مجموعة السيوف',
          description: 'تمثل المجموعة الاغتراب عن النفس الذي يأتي من عزل نفسك وعدم سماعك لصوتك الداخلي.',
          image: 'swords',
          link: 'https://www.eltarot.blog/post/%D8%A7%D9%84%D8%AA%D8%A7%D8%B1%D9%88%D8%AA-%D8%A8%D8%B7%D8%A7%D9%82%D8%A7%D8%AA-'
          + '%D8%A7%D9%84%D8%B3%D8%B1-%D8%A7%D9%84%D8%A3%D8%B5%D8%BA%D8%B1-%D9%85%D8%AC%D9%85%D9%88%D8%B9%D8%A9-%D8%A7%D9%84%D8%B3%D9%8A%D9%88%D9%81',
        },
        {
          title: 'التاروت - بطاقات السر الأصغر : مجموعة العصي',
          description: 'تحدد المجموعة السلوكيات والمهارات والظروف التي يمكن أن تكون محتملة للنجاح.',
          image: 'wands',
          link: 'https://www.eltarot.blog/post/%D8%A7%D9%84%D8%AA%D8%A7%D8%B1%D9%88%D8%AA-%D8%A8%D8%B7%D8%A7%D9%82%D8%A7%D8%AA-'
          + '%D8%A7%D9%84%D8%B3%D8%B1-%D8%A7%D9%84%D8%A3%D8%B5%D8%BA%D8%B1-%D9%85%D8%AC%D9%85%D9%88%D8%B9%D8%A9-%D8%A7%D9%84%D8%B9%D8%B5%D9%8A',
        },
        {
          title: 'التاروت - بطاقات السر الأصغر : مجموعة النجمة الخماسية',
          description: 'تعني المجموعة الموارد المادية أو القدرات الجسدية أو الظروف الصحية.',
          image: 'pentacles',
          link: 'https://www.eltarot.blog/post/%D8%A7%D9%84%D8%AA%D8%A7%D8%B1%D9%88%D8%AA-%D8%A8%D8%B7%D8%A7%D9%82%D8%A7%D8%AA-'
          + '%D8%A7%D9%84%D8%B3%D8%B1-%D8%A7%D9%84%D8%A3%D8%B5%D8%BA%D8%B1-%D9%85%D8%AC%D9%85%D9%88%D8%B9%D8%A9-%D8%A7%D9%84%D9%86%D8%AC%D9%85%D8%A9-%D8%A7%D9%84%D8%AE%D9%85%D8%A7%D8%B3%D9%8A%D8%A9',
        },
      ],
    },
    customRead: {
      cardBoxText: 'إضغط لإختيار البطاقة',
      selectDialogueTitle: {
        text1: 'اختر كارت ل ',
        text2: '',
      },
    },
    history: {
      section1: {
        title: 'التاروت للعرافة',
        paragraph: 'كان الغرض الأصلي من التاروت بمثابة لعبة صالة ، وليس كأداة إلهية'
        + 'يبدو أن العرافة بأوراق اللعب بدأت في الانتشار في أواخر القرن السادس عشر وأوائل القرن السابع عشر'
        + 'على الرغم من أنه في ذلك الوقت ، كان الأمر أكثر بساطة من الطريقة التي نستخدم بها التاروت اليوم.'
        + '<br />'
        + 'بحلول القرن الثامن عشر ، بدأ الناس في تحديد معاني محددة لكل بطاقة ، وحتى تقديم اقتراحات حول كيفية وضعها لأغراض العرافة.',
      },
      section2: {
        title: 'مصر القديمة',
        paragraph: 'كشف مجمع نشره أنطوان كورت دي جبلين أن الرمزية في التارو مستمدة في الواقع من الأسرار الباطنية للكهنة المصريين.'
        + '<br />'
        + 'يشرح دي جبلين أن هذه المعرفة السحرية القديمة نُقلت إلى روما وكُشفت للكنيسة الكاثوليكية والباباوات.'
        + 'الذين أرادوا بشدة الحفاظ على سرية هذه المعرفة الغامضة.'
        + 'في مقالته ، يشرح الفصل الخاص بمعاني التارو الرمزية التفصيلية لعمل التاروت الفني ويربطها بأساطير إيزيس وأوزوريس وآلهة مصرية أخرى.'
        + '<br />'
        + 'ومع ذلك ، يعتبر هذا المفهوم كتاريخ زائف من قبل الكثيرين',
      },
      section3: {
        title: 'التاروت في جميع أنحاء العالم',
        paragraph: 'يمكن إرجاع أسلاف ما نعرفه اليوم باسم بطاقات التاروت إلى أواخر القرن الرابع عشر تقريبًا.'
        + 'ابتكر الفنانون في أوروبا أوراق اللعب الأولى ، والتي كانت تستخدم للألعاب ، وضمت أربع مجاميع مختلفة.'
        + 'كانت هذه المجاميع شبيهة بما لا زلنا نستخدمه اليوم - عصي و أقراص أو نجوم وأكواب وسيوف.'
        + 'بعد عقد أو عقدين من استخدامها ، في منتصف القرن الخامس عشر الميلادي ، بدأ الفنانون الإيطاليون في رسم بطاقات إضافية ، مصورة بشكل كبير ، لإضافتها إلى المجموعات الحالية.',
      },
      section4: {
        title: 'رايدر وايت',
        paragraph: 'كان عالم السحر والتنجيم البريطاني آرثر وايت عضوًا في وسام الفجر الذهبي - ويبدو أنه عدو قديم لأليستر كراولي'
        + 'الذي شارك أيضًا في المجموعة وفروعها المختلفة.'
        + 'اجتمع Waite مع الفنانة باميلا كولمان سميث ، وهي أيضًا عضوة في Golden Dawn ، وأنشأوا مجموعة Rider-Waite Tarot ، والتي نُشرت لأول مرة في عام 1909.'
        + '<br />'
        + 'بناءً على اقتراح Waite ، استخدم سميث العمل الفني Sola Busca للإلهام ، وهناك العديد من أوجه التشابه في'
        + 'رمزية بين النتيجة النهائية لـ "سولا بوسكا" و "سميث". كان سميث أول فنان يستخدم الشخصيات كصور تمثيلية في البطاقات السفلية.'
        + 'بدلاً من إظهار مجرد مجموعة من الأكواب أو العملات المعدنية أو الصولجانات أو السيوف ، قام سميث بدمج شخصيات بشرية في العمل الفني ، والنتيجة هي السطح الأيقوني الذي يعرفه كل قارئ اليوم.',
      },
    },
    learn: {
      section1: {
        title: 'كروت التاروت',
        paragraph: 'تتكون مجموعة كروت التاروت من 78 بطاقة: 22 بطاقة معروفة بـأوراق السر الأعظم  و 40 بطاقة معروفة بـأوراق السر الاصغر و 16 بطاقة محكمة.'
        + '<br />'
        + 'كل بطاقة لها معنى عام مرتبط بها ، لكن تفسير البطاقة يتغير حسب موضع البطاقة في التخطيط وتفاعلها مع البطاقات الأخرى.',
      },
      section2: {
        title: 'القراءات',
        paragraph: 'تتم القراءات غالبًا عن طريق وضع ثلاث بطاقات متتالية ، أو بالتناوب خمس بطاقات في شكل متقاطع.'
        + '<br />'
        + 'يمكن أيضًا إجراء القراءات مع أو بدون السماح ببطاقات عكسية (مقلوبة) ، وستؤدي إلى تفسير بديل.'
        + '&nbsp;غالبًا ما يكون للبطاقات المعكوسة دلالة سلبية أكثر ، ولكن ليس بالضرورة دلالة متشائمة ، وغالبًا ما تعني عكس المعاني العادية.',
      },
      section3: {
        title: 'البطاقات',
        subTitle1: 'السر الأعظم',
        paragraph1: 'كروت السر الأعظم هي مجموعة من 22 بطاقة في مجموعة التاروت ذات ال78 بطاقة.'
        + '<br />'
        + 'إنها بمثابة البطاقات الرئيسية في قراءات التاروت وتختلف عن المجموعات الأربعة المعروفة باسم كروت السر الأصغر.'
        + '<br />'
        + 'الرموز في البطاقات هي نفسها بشكل أساسي في جميع مجموعات التاروت ، على الرغم من أنها قد تختلف من حيث الموضوع وفقًا لفلسفة المصمم.'
        + '<br />'
        + 'Each of the Major Arcana cards has a title, such as The Magician, The Empress, The Lovers, The Moon, The Tower, and so forth.'
        + '&nbsp;و هي مرقمة من الصفر - (الأحمق) - إلى واحد وعشرين - (العالم)',
        subTitle2: 'السر الأصغر',
        paragraph2: 'كروت السر الأصغر هي 56 بطاقة من أوراق التاروت المكونة من 78 بطاقة.'
        + '<br />'
        + 'وهي مقسمة إلى أربع مجموعات لكل منها 14 بطاقة. البدلات المتضمنة هي: الصولجانات ، والأكواب ، والسيوف ، والخماسي.',
      },
    },
    philosophy: {
      quote: 'مثلما لا يمكن للشمعة أن تحترق بدون نار ، لا يستطيع الإنسان أن يعيش بدون حياة روحية.',
      quoter: 'بوذا',
      section1: {
        title: 'تعلم التاروت وتعلم التحكم في مصيرك.',
        paragraph: 'نحن لا نحاول التنبؤ ولا نؤمن بالعرافة.'
        + 'نحن لا نحاول إخبارك بما يجب عليك فعله أو كيف سيكون مستقبلك'
        + 'انت وحدك يمكنك أن تقرر ذلك.'
        + 'نحن نقدم فقط التجربة التي يمكن أن تساعدك في بناء اتصال مع ذاتك والحصول على مزيد من التعاطف مع العالم من حولك.',
      },
      section2: {
        title: 'صدق السحر في قصتك',
        paragraph: 'نعتقد أنه يمكن للشخص تطوير معرفة ذاتية أفضل إذا تم إيلاء المزيد من الاهتمام لأفعاله وسلوكياته اليومية.'
        + 'من خلال العلامات والرموز ، يمكنك قراءة انعكاسات نفسك الداخلية وهذا مهم جدًا لتطوير الذات.',
      },
      section3: {
        title: 'يمكنك التنبؤ بالمستقبل',
        paragraph: '&quot;يمكننا التنبؤ بالمستقبل عندما نعرف كيف تطورت اللحظة الحالية من الماضي.'
        + '<br />'
        + 'يمكن أن يكون التاروت طريقة بديهية تهدف إلى فهم تدفق الحياة ، وربما حتى التنبؤ بالأحداث المستقبلية,'
        + 'حيث ان جميع الأحداث تفسح المجال لقراءة ظروف اللحظة الحالية.&quot;'
        + '<br />'
        + '<b>كارل يونغ</b>',
      },
    },
    support: {
      paragraph1: 'إذا كنت تواجه أي مشاكل أو لديك اقتراحات ، فلا تتردد في الاتصال بنا',
      paragraph2: 'دفعة واحدة فقط مقابل 3 دولارات',
      paragraph3: 'Powered by',
    },
    cardDetails: {
      uprightKeywords: 'كلمات في وضع معتدل',
      reversedKeywords: 'كلمات في وضع معكوس',
      description: 'الوصف',
      uprightMeaning: 'المعنى في وضع معتدل',
      reversedMeaning: 'المعنى في وضع معكوس',
      keywords: 'كلمات',
      meaning: 'المعنى',
      advice: 'النصيحة',
    },
    filterBox: {
      filter: 'إظهار',
      all: 'الكل',
    },
    searchField: {
      placeholder: 'إبحث بإسم البطاقة',
    },
    cardHoverText: {
      text1: '-- إضغط للإضافة >',
      text2: '-- التفاصيل >',
    },
    privacy: {
      p1: 'في Eltarot ، يمكن الوصول إليه من http://eltarot.app ، تتمثل إحدى أولوياتنا الرئيسية في خصوصية زوارنا.'
      + 'تحتوي وثيقة سياسة الخصوصية هذه على أنواع المعلومات التي يتم جمعها وتسجيلها بواسطة Eltarot وكيف نستخدمها.',
      p2: 'إذا كانت لديك أسئلة إضافية أو تحتاج إلى مزيد من المعلومات حول سياسة الخصوصية الخاصة بنا ، فلا تتردد في الاتصال بنا.',
      p3: 'تنطبق سياسة الخصوصية هذه فقط على أنشطتنا عبر الإنترنت وهي صالحة لزوار موقعنا الإلكتروني فيما يتعلق بالمعلومات التي شاركوها و / أو يجمعونها في Eltarot.'
      + 'لا تنطبق هذه السياسة على أي معلومات يتم جمعها في وضع عدم الاتصال أو عبر قنوات أخرى غير هذا الموقع.',
      t1: 'موافقة',
      p4: 'باستخدام موقعنا ، فإنك بذلك توافق على سياسة الخصوصية الخاصة بنا وتوافق على شروطها.',
      t2: 'المعلومات التي نجمعها',
      p5: 'سيتم توضيح المعلومات الشخصية التي يُطلب منك تقديمها ، وأسباب مطالبتك بتقديمها ، في الوقت الذي نطلب منك فيه تقديم معلوماتك الشخصية.',
      p6: 'إذا اتصلت بنا مباشرة ، فقد نتلقى معلومات إضافية عنك مثل اسمك وعنوان بريدك الإلكتروني ، '
      + 'محتويات الرسالة و / أو المرفقات التي قد ترسلها إلينا ، وأي معلومات أخرى قد تختار تقديمها.',
      p7: 'عند التسجيل للحصول على حساب ، قد نطلب معلومات الاتصال الخاصة بك ، بما في ذلك عناصر مثل الاسم  وعنوان البريد الإلكتروني',
      t3: 'كيف نستخدم معلوماتك',
      p8: 'نستخدم المعلومات التي نجمعها بطرق مختلفة ، بما في ذلك:',
      l1: 'توفير وتشغيل وصيانة التطبيق',
      l2: 'تحسين وتخصيص و زيادة حجم التطبيق',
      l3: 'فهم وتحليل كيفية استخدامك للتطبيق',
      l4: 'تطوير منتجات وخدمات وميزات ووظائف جديدة',
      l5: 'التواصل معك ، إما مباشرة أو من خلال أحد شركائنا ، بما في ذلك  خدمة العملاء ، لتزويدك بالتحديثات والمعلومات الأخرى المتعلقة بالتطبيق ، ولأغراض تسويقية وترويجية',
      l6: 'أرسال  رسائل البريد الإلكتروني',
      l7: 'البحث عن الاحتيال ومنعه',
      t4: 'ملفات السجل',
      p9: 'تتبع Eltarot الإجراء القياسي لاستخدام ملفات السجل. تسجل هذه الملفات الزوار عندما يزورون مواقع الويب. '
      + 'تقوم جميع شركات الاستضافة بذلك وجزء من تحليلات خدمات الاستضافة.'
      + 'تتضمن المعلومات التي يتم جمعها بواسطة ملفات السجل عناوين بروتوكول الإنترنت (IP) ونوع المستعرض وموفر خدمة الإنترنت (ISP) وطابع التاريخ والوقت وصفحات الإحالة / الخروج وربما عدد النقرات.'
      + 'هذه ليست مرتبطة بأي معلومات لتحديد الهوية الشخصية. الغرض من المعلومات هو تحليل الاتجاهات وإدارة الموقع وتتبع حركة المستخدمين على الموقع وجمع المعلومات الديموغرافية.',
      t5: 'ملفات تعريف الارتباط وإشارات الويب',
      p10: 'مثل أي موقع ويب آخر ، تستخدم Eltarot ملفات تعريف الارتباط. تُستخدم ملفات تعريف الارتباط هذه لتخزين المعلومات بما في ذلك تفضيلات الزوار والصفحات الموجودة على موقع الويب التي وصل إليها الزائر أو زارها.'
      + ' تُستخدم المعلومات لتحسين تجربة المستخدمين من خلال تخصيص محتوى صفحة الويب الخاصة بنا بناءً على نوع متصفح الزوار و / أو معلومات أخرى.',
      p11: {
        text: 'لمزيد من المعلومات العامة حول ملفات تعريف الارتباط ، يرجى قراءة',
        link: 'هذا الرابط',
      },
      t6: 'كوكيز دبل كليك DoubleClick DART',
      p12: {
        text: 'Google هو أحد الخدمات على موقعنا. Google يستخدم ملفات تعريف الارتباط ، المعروفة باسم ملفات تعريف الارتباط DART ، لعرض الإعلانات على زوار موقعنا بناءً على زيارتهم لموقع www.eltarot.app والمواقع الأخرى على الإنترنت.'
        + 'ومع ذلك ، قد يختار الزوار رفض استخدام ملفات تعريف الارتباط DART عن طريق زيارة سياسة الخصوصية الخاصة بإعلانات Google وشبكة المحتوى على العنوان التالي - ',
        link: 'https://policies.google.com/technologies/ads',
      },
      t7: 'شركاء الإعلانات',
      p13: 'قد يستخدم بعض المعلنين على موقعنا ملفات تعريف الارتباط وإشارات الويب. شركاء الإعلان لدينا مدرجون أدناه. '
      + 'لكل من شركائنا الإعلانيين سياسة الخصوصية الخاصة بهم لسياساتهم الخاصة ببيانات المستخدم. لتسهيل الوصول ، قمنا بالربط التشعبي بسياسات الخصوصية الخاصة بهم أدناه.',
      p14: 'Google',
      p15: {
        link: 'https://policies.google.com/technologies/ads',
      },
      t8: 'سياسات خصوصية شركاء الإعلانات',
      p16: 'يمكنك الرجوع إلى هذه القائمة للعثور على سياسة الخصوصية لكل من شركاء الإعلان في Eltarot.',
      p17: 'تستخدم خوادم الإعلانات الخارجية أو شبكات الإعلانات تقنيات مثل ملفات تعريف الارتباط أو جافا سكريبت أو منارات الويب المستخدمة في الإعلانات'
      + 'والروابط الخاصة بكل منها والتي تظهر على Eltarot ، والتي يتم إرسالها مباشرة إلى متصفح المستخدمين. يتلقون عنوان IP الخاص بك تلقائيًا عند حدوث ذلك. '
      + ' تُستخدم هذه التقنيات لقياس فعالية حملاتهم الإعلانية و / أو لتخصيص محتوى الإعلان الذي تراه على مواقع الويب التي تزورها.',
      p18: 'لاحظ أن Eltarot ليس لديها حق الوصول أو التحكم في ملفات تعريف الارتباط هذه التي يستخدمها معلنون من جهات خارجية.',
      t9: 'سياسات خصوصية الطرف الثالث',
      p19: 'لا تنطبق سياسة خصوصية Eltarot على المعلنين أو المواقع الإلكترونية الأخرى. وبالتالي ، فإننا ننصحك بالرجوع إلى سياسات الخصوصية الخاصة بخوادم إعلانات الجهات الخارجية للحصول على معلومات أكثر تفصيلاً.'
      + 'قد يتضمن ممارساتهم وتعليماتهم حول كيفية إلغاء الاشتراك في خيارات معينة.',
      p20: 'يمكنك اختيار تعطيل ملفات تعريف الارتباط من خلال خيارات المتصفح الفردية الخاصة بك.'
      + 'لمعرفة المزيد من المعلومات التفصيلية حول إدارة ملفات تعريف الارتباط مع متصفحات الويب المحددة ، يمكن العثور عليها في مواقع الويب الخاصة بالمتصفحات.',
      t10: 'حقوق خصوصية CCPA (لا تبيع معلوماتي الشخصية)',
      p21: 'بموجب قانون حماية خصوصية المستهلك في كاليفورنيا ، من بين حقوق أخرى ، يحق للمستهلكين في كاليفورنيا:',
      p22: 'اطلب من الشركة التي تجمع بيانات شخصية للمستهلك أن تكشف عن فئات وأجزاء معينة من البيانات الشخصية التي جمعتها الشركة عن المستهلكين.',
      p23: 'اطلب من شركة ما حذف أي بيانات شخصية عن المستهلك جمعتها الشركة.',
      p24: 'اطلب من الشركة التي تبيع بيانات شخصية للمستهلك ، ألا تبيع البيانات الشخصية للمستهلك.',
      p25: 'إذا قمت بتقديم طلب ، فلدينا شهر واحد للرد عليك. إذا كنت ترغب في ممارسة أي من هذه الحقوق ، يرجى الاتصال بنا.',
      t11: 'حقوق حماية بيانات القانون العام لحماية البيانات',
      p26: 'نود التأكد من أنك على دراية كاملة بجميع حقوق حماية البيانات الخاصة بك. يحق لكل مستخدم ما يلي:',
      p27: 'الحق في الوصول - يحق لك طلب نسخ من بياناتك الشخصية. قد نفرض عليك رسومًا رمزية مقابل هذه الخدمة.',
      p28: 'الحق في التصحيح - يحق لك أن تطلب منا تصحيح أي معلومات تعتقد أنها غير دقيقة.'
      + 'لديك أيضًا الحق في طلب إكمال المعلومات التي تعتقد أنها غير كاملة.',
      p29: 'الحق في المحو - يحق لك طلب مسح بياناتك الشخصية ، في ظل ظروف معينة.',
      p30: 'الحق في تقييد المعالجة - يحق لك طلب تقييد معالجة بياناتك الشخصية ، في ظل ظروف معينة.',
      p31: 'الحق في الاعتراض على المعالجة - يحق لك الاعتراض على معالجتنا لبياناتك الشخصية ، في ظل ظروف معينة.',
      p32: 'الحق في نقل البيانات - يحق لك أن تطلب منا نقل البيانات التي جمعناها إلى مؤسسة أخرى ، أو إليك مباشرةً ، في ظل ظروف معينة.',
      p33: 'إذا قمت بتقديم طلب ، فلدينا شهر واحد للرد عليك. إذا كنت ترغب في ممارسة أي من هذه الحقوق ، يرجى الاتصال بنا.',
      t12: 'معلومات الأطفال',
      p34: 'جزء آخر من أولوياتنا هو إضافة حماية للأطفال أثناء استخدام الإنترنت. نحن نشجع الآباء والأوصياء على مراقبة نشاطهم عبر الإنترنت والمشاركة فيه و / أو مراقبته وتوجيهه.',
      p35: 'لا تجمع شركة Eltarot عن قصد أي معلومات تعريف شخصية من الأطفال الذين تقل أعمارهم عن 13 عامًا.'
      + ' إذا كنت تعتقد أن طفلك قدم هذا النوع من المعلومات على موقعنا ، فنحن نشجعك بشدة على الاتصال بنا على الفور وسنبذل قصارى جهدنا للإزالة على الفور هذه المعلومات من سجلاتنا.',
    },
    spread: {
      form: {
        readerName: 'إسم القارئ',
        readerBirthDate: 'تاريخ ميلاد القارئ',
        readSubject: 'موضوع القراءة',
        readSubjectList: ['قراءة عامة', 'الحب و العلاقات', 'العمل و الماديات'],
        readQuestion: 'سؤال القراءة',
        enableReversed: 'تفعيل البطاقات المعكوسة',
      },
    },
    reader: {
      readInfo: {
        readDate: 'تاريخ القراءة',
        readerName: 'إسم القارئ',
        readerBirthDate: 'تاريخ ميلاد القارئ',
        readSubject: 'موضوع القراءة',
        readQuestion: 'سؤال القراءة',
      },
      answer: {
        remember: 'تذكر دائمًا أن أفضل طريقة للإجابة على أسئلتك هي التفكير العقلاني',
        oneCard: {
          yes: 'القراءة تميل الى الإجابة بنعم',
          no: 'القراءة تميل الى الإجابة بلا',
        },
        twoCards: {
          opt1: 'القراءة تميل الى الخيار الأول',
          opt2: 'القراءة تميل الى الخيار الثاني',
        },
      },
    },
  },
  buttons: {
    landing: {
      startNormalRead: 'قراءة عادية',
      startCustomRead: 'قراءة مخصصة',
      startReading: 'إبدأ القراءة',
      androidVersion: 'نسخة اندرويد',
      allCards: 'اظهر البطاقات',
      blogPost: 'اقرأ المزيد',
      allPosts: 'كل التدوينات',
      customRead: 'جرب الان',
    },
    myReads: {
      backToSavedReads: 'العودة الى القراءات المحفوظة',
    },
    editCard: 'تعديل',
    saveRead: 'حفظ القراءة',
    newRead: 'قراءة جديدة',
    spread: 'انشر البطاقات',
    shuffle: 'قلب البطاقات',
    enableReversed: 'تفعيل البطاقات المعكوسة',
    resendVerificationEmail: 'إضغط لإعادة إرسال رسالة التأكيد',
    cardFullDetails: 'المعلومات الكاملة',
    support: 'إدعمنا',
    start: 'إبدا',
    save: 'حفظ',
  },
  links: {
    allPosts: 'https://www.eltarot.blog/the-review',
    playStoreListing: 'https://play.google.com/store/apps/details?id=com.t2madrid.Eltarot',
  },
  notifications: {
    updateCardSuccess: 'تم تحديث البطاقة بنجاح',
    updateCardFail: 'تعذر تحديث البطاقة. حدث خطأ.',
    readSaveLimit: 'لقد تجاوزت الحد الأقصى للقراءات المحفوظة. يرجى إزالة واحدة على الأقل من القراءات المحفوظة لتتمكن من المتابعة',
    readSaveSuccess: 'تم حفظ القراءة بنجاح',
    readSaveFail: 'تعذر حفظ القراءة',
    readLoadFail: 'تعذر تحميل البطاقة',
    userPrefFail: 'تعذر تحميل بيانات المستخدم',
    readRemovedSuccess: 'تم حذف القراءة بنجاح',
    readRemovedFail: 'تعذر حذف القراءة',
    subscriptionPaymentFail: 'هناك مشكلة في قبول عملية الدفع. برجاء المحاولة مرة اخرى',
    subscriptionPaymentSuccess: 'تم قبول الدعم بنجاح. استمتع بالقراءة',
    clientOffline: 'يبدو أنك غير متصل بالإنترنت. لا يزال بإمكانك استخدام التطبيق ولكن يوصى باستخدامه مع الاتصال بالإنترنت للحصول على تجربة أفضل.',
  },
  messages: {
    readPref: 'ادخل المعلومات المطلوبة لتبدأ القراءة',
    cardsNotShuffled: 'إضغط على البطاقات لخلطها',
    cardsNotSpread: 'إضغط على البطاقات لتوزيعها',
    selectCards: {
      select: 'اختر ',
      cardsToContinue: ' كروت للمتابعة',
      toContinue: ' للمتابعة ',
    },
    flipCards: 'إضغط على كل البطاقات لكشف  معنى القراءة',
    emailNotVerified: '<b>برجاء تأكيد البريد الإلكتروني</b>'
    + '<br />'
    + 'البريد الإلكتروني غير مؤكد',
    verifyEmail: '<b>برجاء تأكيد البريد الإلكتروني</b>'
    + '<br />'
    + 'تم إرسال بريد إلكتروني للتحقق من عنوان بريدك الإلكتروني. يرجى اتباع التعليمات الواردة في البريد الإلكتروني لمتابعة استخدام التطبيق.',
    verificationSuccess: '<b>تم التحقق من عنوان بريدك الإلكتروني بنجاح</b>'
    + '<br />'
    + 'يمكنك البدء في استخدام التطبيق. قراءة سعيدة',
    verificationFail: '<b> هناك مشكلة في التحقق من عنوان بريدك الإلكتروني.</b>'
    + '<br />'
    + 'انقر فوق الرابط أدناه لإعادة إرسال رسالة التحقق.',
    reader: {
      advice: {
        general: 'إكشف البطاقات لإظهار النصائح',
        oneCard: 'إكشف البطاقات لإظهار الإجابة',
        twoCards: 'إكشف البطاقات لإظهار الإختيار الأفضل',
      },
    },
    cookieBar: {
      message: 'يستخدم هذا الموقع ملفات تعريف الارتباط لتحسين تجربة المستخدم.',
      link: 'سياسة الخصوصية',
      button: 'موافقة',
    },
    validationErrors: {
      required: {
        text1: 'برجاء ملء هذه الخانة',
        text2: '',
      },
      maxLen: {
        text1: 'الحد الأقصى',
        text2: '',
      },
      minLen: {
        text1: 'الحد الأدنى',
        text2: '',
      },
      email: {
        text1: 'برجاء إدخال بريد إلكتروني صحيح',
        text2: '',
      },
      numbers: {
        text1: 'يمكن إدخال الأرقام فقط',
        text2: '',
      },
      match: {
        text1: 'يجب ان يتوافق مع',
        text2: '',
      },
    },
  },
  loadingMessages: {
    loadingCustomReadSlots: 'تنظيم خانات القراءة',
    loadingSavedReads: 'تحميل',
    savingRead: 'حفظ القراءة...',
    loadingCardDetails: 'تحميل التفاصيل...',
    validatingEmail: 'تأكيد البريد الإلكتروني ...',
  },
  navLinks: {
    homePage: 'الرئيسية',
    cardsList: 'البطاقات',
    readsList: 'قراءة',
    customRead: 'كون قراءتك',
    authintication: 'تسجيل الدخول',
    logout: 'خروج',
  },
  footerLinks: {
    philosophy: 'الفلسفة',
    history: 'التاريخ',
    learn: 'تعلم',
    privacy: 'سياسة الخصوصية',
    contact: 'اتصل بنا',
  },
  userLinks: {
    myReads: 'حسابي',
    logout: 'خروج',
    users: 'Users',
  },
  suits: {
    all: 'الكل',
    major: 'السر الأعظم',
    cups: 'الكؤوس',
    pentacles: 'النجمة الخماسية',
    wands: 'العصي',
    swords: 'السيوف',
  },
  fonts: {
    cardTitle: {
      family: 'Reem Kufi',
    },
  },
}
