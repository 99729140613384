import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { Container } from '@material-ui/core'

import { loStyles } from './styles'

import AppMeta from './AppMeta'
import AppNav from './AppNav'
import AppFooter from './AppFooter'
import FloatingButtons from './FloatingButtons'
import MobileNavigation from './MobileNavigation'
import CookieBar from './CookieBar'

const AppLayout = ({ children, toggleTheme, theme }) => {
  const classes = loStyles()
  const location = useLocation()
  const { apptype } = queryString.parse(location.search)
  const noNav = apptype && apptype === 'blog'

  return (
    <>
      <AppMeta />
      {!noNav && <AppNav toggleTheme={toggleTheme} theme={theme} />}
      <Container maxWidth={false} className={classes.appContainer}>
        {children}
        {!noNav && <FloatingButtons toggleTheme={toggleTheme} theme={theme} />}
      </Container>
      {!noNav && <AppFooter />}
      {!noNav && <MobileNavigation />}
      {!noNav && <CookieBar />}
    </>
  )
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  toggleTheme: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
}

export default AppLayout
