import React, { lazy, Suspense } from 'react'

import { useHistory } from 'react-router-dom'

import {
  Box,
} from '@material-ui/core'

import { landingStyles } from './styles'

const HeroBlog = lazy(() => import('../../components/Landing/HeroBlog'))

const LandingBlog = () => {
  const classes = landingStyles()
  const history = useHistory()

  const normalReadHandler = () => {
    history.push('/reads/?lang=ar&&apptype=blog')
  }

  const customReadHandler = () => {
    history.push('/customRead')
  }

  return (
    <Box className={classes.landingContainer}>
      <Suspense fallback="Loading...">
        <HeroBlog onNormalRead={normalReadHandler} onCustomRead={customReadHandler} />
      </Suspense>
    </Box>
  )
}

export default LandingBlog
